import React, { useEffect, useState } from "react";
import { Business, convertCurrency, dummyPrevItemArray } from "../../../consts";
import BuyerInfoModal from "../buyerInfoModal";

import "./styles.scss";

function CompletedBids(props) {
  const [prevItems, setPrevItems] = useState([]);
  const [buyerIndex, setBuyerIndex] = useState(-1);

  useEffect(() => {
    setPrevItems(dummyPrevItemArray);
  }, []);

  const onViewAddress = (idx) => {
    //
  };

  const onViewMore = (idx) => {
    //
  };

  const onToggleModal = () => {
    if (buyerIndex !== -1) {
      setBuyerIndex(-1);
    }
  };
  const onShowBuyer = (idx) => {
    setBuyerIndex(idx);
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title px-20">Completed Items</p>
      </div>
    );
  };

  const renderRow = (product, index) => {
    const { link, detail, bidList, buyer } = product;

    const remain = "Awaiting Shipment";

    return (
      <div
        key={"row" + index}
        className="row-item px-10 py-10 md-px-10 sm-py-20 md-py-20"
      >
        <div className="product flex w-full sm-w-2-5 md-w-2-5 pb-20 sm-pb-0 md-pb-0">
          <div className="left">
            <img src={"." + link} alt="" />
          </div>
          <div className="right">
            <div className="detail">{detail}</div>
            <div className="status">
              <Business /> <span>{remain}</span>
            </div>
          </div>
        </div>

        <div className="other flex w-full sm-w-3-5 md-w-3-5">
          <div className="buyer w-1-3">
            <div className="buyer-title">Buyer</div>
            <div className="buyer-value" onClick={() => onShowBuyer(index)}>
              {buyer.name}
            </div>
          </div>

          <div className="price w-1-3">
            <div className="price-title">Sold By</div>
            <div className="price-value">{convertCurrency(buyer.bidPrice)}</div>
          </div>

          <div className="address w-1-3" onClick={() => onViewAddress(index)}>
            View&nbsp;Address
          </div>

          <div className="more">
            <i className="fa fa-ellipsis-v" onClick={() => onViewMore()} />
          </div>
        </div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="results-list w-full my-10">
        {prevItems.map((item, idx) => renderRow(item, idx))}
      </div>
    );
  };

  return (
    <>
      <div className="completed-bids-form lg-px-10 md-px-20 sm-px-0">
        {renderTitle()}
        {renderResults()}
      </div>
      {buyerIndex !== -1 && (
        <BuyerInfoModal
          isOpen={buyerIndex !== -1}
          onToggle={onToggleModal}
          data={prevItems[buyerIndex].buyer}
        />
      )}
    </>
  );
}

export default CompletedBids;
