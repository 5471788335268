import { get_profile } from "./profile";
import { toast } from 'react-toastify';

export * from "./auth";

const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

export function showError(err) {
  if (err && err.error) {
    toast.error(capitalize(err.error));
    return;  
  }
  toast.error('Database/Server connection is failed. Please try again.');
}

export function showSuccessToast(message, options) {
  toast.success(message || 'Succesfully done.', options);
}

export async function checkAuth(e) {
  if (e.response) {
    const { status, data } = e.response;
    if (status === 500) {
      showError();
      return;
    }
    if (status === 401) {
      window.location.href = "/login";
      return;
    }

    showError(data);
  }
  // let profile;
  // try {
  //   profile = await get_profile();
  // } catch (e) {
  //   const str = localStorage.getItem("profile");
  //   if (str) {
  //     profile = JSON.parse(str);
  //   }
  // }
  // if (!profile) {
  //   window.location.href = "/register";
  // }
}
