import React, { useEffect, useState } from "react";
import {
  Clock,
  convertCurrency,
  dummyCurrentItemArray,
  getRemainToday,
  Highest,
  Lowest,
  MoreSubMenus
} from "../../../consts";
import DropdownMenu from "../../dropdownMenu";

import Loader from "../../loader";
import ItemBids from "../itemBids";

import "./styles.scss";

function CurrentBids(props) {
  const [currentItems, setCurrentItems] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    setCurrentItems(dummyCurrentItemArray);
  }, []);

  const onViewBids = (idx) => {
    setSelectedIndex(idx);
    //
  };
  const onCloseBids = () => {
    setSelectedIndex(-1);
  };
  const onViewMore = (idx) => {
    //
  };

  const onMoreSelect = (index, key) => {
    console.log("selected: ", index, key);
    if (key === 'bids') {
      onViewBids(index);
    }
  };

  const getLowHighBid = (bidArray) => {
    if (!bidArray || bidArray.length === 0) {
      return { low: -1, high: -1 };
    }

    var lowest = Number.POSITIVE_INFINITY;
    var highest = Number.NEGATIVE_INFINITY;

    bidArray.forEach(({ bidPrice }) => {
      if (bidPrice < lowest) lowest = bidPrice;
      if (bidPrice > highest) highest = bidPrice;
    });

    return { low: lowest, high: highest };
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title px-20">Current Bids</p>
      </div>
    );
  };

  const renderRow = (product, index) => {
    const { link, detail, endTime, bidList } = product;

    const { low, high } = getLowHighBid(bidList);

    let remain = getRemainToday(endTime);
    let isEnd = false;
    if (remain === "") {
      remain = "Finished";
      isEnd = true;
    } else {
      remain += " remaining";
    }

    return (
      <div
        key={"row" + index}
        className="row-item px-10 py-10 md-px-10 sm-py-20 md-py-20"
      >
        <div className="product flex w-full sm-w-2-5 md-w-2-5 pb-20 sm-pb-0 md-pb-0">
          <div className="left">
            <img src={"." + link} alt="" />
          </div>
          <div className="right">
            <div className="detail">{detail}</div>
            <div className="status">
              <Clock /> <span>{remain}</span>
            </div>
          </div>
        </div>

        <div className="other flex w-full sm-w-3-5 md-w-3-5">
          <div className="bids w-1-5" onClick={() => onViewBids(index)}>
            {bidList.length}&nbsp;bids
          </div>

          <div className="price w-2-5">
            <div className="price-title">Lowest Bid</div>
            <div className="price-value">
              <Lowest /> {convertCurrency(low)}
            </div>
          </div>

          <div className="price w-2-5">
            <div className="price-title">Highest Bid</div>
            <div className="price-value">
              <Highest /> {convertCurrency(high)}
            </div>
          </div>

          <div className="more">
            <DropdownMenu
              options={MoreSubMenus}
              onChange={(key) => {
                onMoreSelect(index, key);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="results-list w-full my-10">
        {currentItems.map((item, idx) => renderRow(item, idx))}
      </div>
    );
  };

  return selectedIndex === -1 ? (
    <div className="current-bids-form lg-px-10 md-px-20 sm-px-0">
      {renderTitle()}
      {renderResults()}
    </div>
  ) : (
    <ItemBids item={currentItems[selectedIndex]} onClose={onCloseBids} />
  );
}

export default CurrentBids;
