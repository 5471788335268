import http from "./http";

export async function get_items(start, limit) {
  try {
    let response = await http.get(
      `/api/items?start=${start}&limit=${limit}`
    );

    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function post_item(params) {
  console.log('async call', params)
  try {
    let response = await http.post(`/api/items`, params);
    console.log(response);
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function get_item(item_id) {
  try {
    let response = await http.get(`/api/items/${item_id}`);
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function post_item_images(item_id, imageList) {
  try {
    let res = await http.up_many(`/api/items/${item_id}/images`, imageList);
    return res.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}
