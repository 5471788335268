import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import Modal from "react-modal";
import { renderGroup, renderInput } from "../../../consts";

import "./styles.scss";

const ProfileEditModal = ({ isOpen, onUpdate, onCancel, profile }) => {

  const onHandleSubmit = (formData) => {
    console.log(formData);

    let { email, first_name, last_name, phone } = formData;
    let values = {
      email: email,
      first_name: first_name || profile.first_name,
      last_name: last_name || profile.last_name,
      phone: phone || profile.phone,
    };

    if (onUpdate) {
      onUpdate(values);
    }
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title text-center">Edit Personal Information</p>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <>
        <div className="title">Email</div>
        <div className="editor">{renderInput("email", "", true)}</div>

        <div className="title">First Name</div>
        <div className="editor">{renderInput("first_name")}</div>

        <div className="title">Last Name</div>
        <div className="editor">{renderInput("last_name")}</div>

        <div className="title">Phone Number</div>
        <div className="editor">{renderInput("phone")}</div>
      </>
    );
  };

  const renderForm = () => {
    return (
      <Form onSubmit={onHandleSubmit} initialValues={profile}>
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className="form-content">
            {renderBody(values)}
            <div className="edit-buttons">
              {renderGroup(
                <button
                  className="btn-update"
                  type="submit"
                  disabled={submitting}
                >
                  <span>Update</span>
                </button>,
                <button
                  className="btn-cancel"
                  type="button"
                  disabled={submitting}
                  onClick={onCancel}
                >
                  <span>Cancel</span>
                </button>
              )}
            </div>
          </form>
        )}
      </Form>
    );
  };

  return (
    <div className="profile-edit">
      <Modal
        className="profile-edit-modal"
        isOpen={isOpen}
        toggle={onCancel}
        size="lg"
        backdrop={true}
        shouldCloseOnOverlayClick={true}
      >
        {renderTitle()}
        {renderForm()}
      </Modal>
    </div>
  );
};

export default ProfileEditModal;
