import React, { useEffect, useState } from "react";
import { PORTAL_MENU } from "../../consts";
import AddNewItem from "./addNewItem";
import CurrentBids from "./currentBids";

import PortalMenu from "./portalMenu";

import "./styles.scss";
import PreviousBids from "./previousBids/index";
import CompletedBids from "./completedBids";
import ProfileForm from "./profile";
import OpenBids from './openBids/index';
import PastBids from './pastBids/index';

function Dashboard(props) {
  const [key, setKey] = useState(PORTAL_MENU.products[0].url);
  const [linkList, setLinkList] = useState([]);
  const [isFirstLoad, setFirstLoad] = useState(true);

  const onSelectItem = (e, newKey) => {
    e.preventDefault();

    if (key === newKey) {
      return;
    }

    setKey(newKey);
    window.history.pushState(null, "", `${newKey}`);
  };

  useEffect(() => {
    updateLinkList();
  }, []);

  useEffect(() => {
    const splits = window.location.pathname.split("/");
    const path0 = splits[1];
    const path1 = splits[2];
    const pathName = "/" + (path0 || "") + "/" + (path1 || "");

    let idx = linkList.indexOf(pathName);

    if (isFirstLoad) {
      setFirstLoad(false);
      return;
    }

    if (!pathName || !linkList.includes(pathName)) {
      if (path0 === "profile") {
        setKey(linkList[5]);
        window.history.pushState(null, "", `${linkList[5]}`);
        return;
      }

      if (key !== linkList[0]) {
        setKey(linkList[0]);
      }
      window.history.pushState(null, "", `${linkList[0]}`);
      return;
    }

    let category = pathName;
    if (idx === 0) {
      idx = 1;
      category = linkList[0];
    }
    if (key !== category) {
      setKey(category);
    }
  }, [linkList]);

  const updateLinkList = () => {
    let menus = [];
    const portals = Object.entries(PORTAL_MENU);
    portals.forEach(([key, list]) => {
      console.log(list);
      const items = list.map(({ url }) => url);

      menus = [...menus, ...items];
    });

    setLinkList(menus);
  };

  const renderTitle = () => {
    return (
      <div className="dashboard-header flex flex-jc my-10 px-10 lg-px-10 md-px-20">
        My Portal
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <div className="dashboard-menu w-full lg-w-1-3 md-w-1-4 mb-30 md-mb-0">
        <PortalMenu
          menu={PORTAL_MENU}
          selected={key}
          onSelectItem={onSelectItem}
        />
      </div>
    );
  };

  const renderResults = () => {
    let index2 = 3;
    return (
      <div className="dashboard-results w-full lg-w-2-3 md-w-3-4">
        {/* {key === linkList[0] && <CurrentBids />}
        {key === linkList[1] && <PreviousBids />}
        {key === linkList[2] && <CompletedBids />}
        {key === linkList[3] && <CurrentBids />}
        {key === linkList[4] && <AddNewItem />}
        */}

        {key === linkList[0] && <OpenBids />}
        {key === linkList[1] && <PastBids />}
        {key === linkList[2] && <AddNewItem />}
        {key === linkList[index2+0] && <ProfileForm key={key} />}
        {key === linkList[index2+1] && <ProfileForm key={key} />}
        {key === linkList[index2+2] && <ProfileForm key={key} />}
        {key === linkList[index2+3] && <div />}
        {key === linkList[index2+4] && <div />}
      </div>
    );
  };

  return (
    <div className="dashboard-form lg-px-10 md-px-20 sm-px-0">
      {renderTitle()}

      <div className="flex flex-wrap">
        {renderMenu()}
        {renderResults()}
      </div>
    </div>
  );
}

export default Dashboard;
