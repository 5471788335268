import http from "./http";

export async function get_profile(id = "self") {
  try {
    let { data } = await http.get(`/api/profiles/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function upload_image(image) {
  console.log("upload_image", image);
  try {
    let { data } = await http.up("/api/profiles/self/images", image);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function update_profile(id, params) {
  try {
    let { data } = await http.put(`/api/profiles/${id || "self"}`, params);
    return data;
  } catch (e) {
    throw e;
  }
}
