import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  Clock,
  convertCurrency,
  getImageUri,
  getRemainToday,
  Placeholder,
} from "../../../consts";

import "./styles.scss";

const IncreaseBidModal = ({ isOpen, onBid, onCancel, data }) => {
  const [newBid, setNewBid] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const multiples = [0.5, 2, 5, 10, 15];

  useEffect(() => {
    setNewBid(customBid(data.highest_bid, multiples[0]));
  }, []);

  const currency = (value) => {
    let bid = parseInt(value);
    if (bid > 999) {
      if (value % 1000 === 0) {
        let thous = (bid / 1000) >> 0;
        return "$" + thous + "K";
      }
      return "$" + (bid / 1000).toFixed(1) + "K";
    }
    return "$" + bid;
  };

  const customBid = (highest, multiple) => {
    let bid = parseInt(highest);
    return bid + ((bid / 100) >> 0) * 10 * multiple;
  };

  const onSelectBid = (idx) => {
    if (selectedIndex === idx) {
      return;
    }
    setSelectedIndex(idx);
    if (idx !== -1) {
      setNewBid(customBid(data.highest_bid, multiples[idx]));
    }
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title text-center">Increase Your Bid</p>
      </div>
    );
  };

  const renderContent = () => {
    const { name, description, image_keys, exp_time, highest_bid, amount } =
      data;

    const expired = (new Date(exp_time).getTime() / 1000) >> 0;
    let remain = getRemainToday(expired);
    let isEnd = false;
    if (remain === "") {
      remain = "Finished";
      isEnd = true;
    } else {
      remain += "";
    }

    return (
      <div className="content w-full my-10">
        <div className="flex bid-item">
          <div className="left">
            <img
              src={getImageUri(image_keys[0])}
              alt=""
              onError={({ target }) => {
                target.onError = null;
                target.src = Placeholder;
              }}
            />
          </div>
          <div className="right">
            <div className="name">{name}</div>
            <div className="detail">{description}</div>
          </div>
        </div>
        <div className="flex bid-amount">
          <div className="flex left">
            <div className="first">
              <div className="amount">Highest bid amount</div>
              <div className="value">{convertCurrency(highest_bid)}</div>
            </div>
            <div className="view-bid">View bids</div>
          </div>

          <div className="flex right">
            <div className="first">
              <div className="amount">Your bid amount</div>
              <div className="value">{convertCurrency(amount)}</div>
            </div>
            <div className="remain">
              <Clock /> <span>{remain}</span>
            </div>
          </div>
        </div>

        <div className="increase-bid">
          <div className="bid-title">Increase Bid</div>

          <input
            className={`form-control bid-input`}
            type="number"
            name="newBid"
            value={newBid}
            disabled={selectedIndex !== -1}
            onChange={(e) => {
              setNewBid(e.target.value);
            }}
          />
        </div>

        <div className="flex custom-bid">
          {multiples.map((value, idx) => {
            return (
              <div
                key={`custom_${idx}`}
                className={
                  "custom-item" + (idx === selectedIndex ? " selected" : "")
                }
                onClick={() => onSelectBid(idx, highest_bid)}
              >
                {currency(customBid(highest_bid, value))}
              </div>
            );
          })}
          <div
            key={`custom_other`}
            className="custom-other"
            onClick={() => onSelectBid(-1, highest_bid)}
          >
            use custom bid
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="increase-bid">
      <Modal
        className="increase-bid-modal"
        isOpen={isOpen}
        toggle={onCancel}
        size="lg"
        backdrop={true}
        shouldCloseOnOverlayClick={true}
      >
        {renderTitle()}
        {renderContent()}

        <div className="flex w-full text-center">
          <button className="btn-place" type="btn" onClick={() => {onBid(newBid)}}>
            <span>Place Bid For {currency(newBid)}</span>
          </button>
          <button className="btn-exit" type="btn" onClick={onCancel}>
            <span>CLOSE</span>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default IncreaseBidModal;
