import React from "react";
import withRouter from "../../routes/withRouter";
import "./styles.scss";

function GuestHeader(props) {
  const { router } = props;
  return (
    <div
      className={`header${
        router.location.pathname === "/" ? " main-page-header" : ""
      }`}
    >
      <div className="container">
        <div className="flex flex-wrap items-center row-mx-15">
          <div className="page-welcome">Welcome to SEALEDBIDS</div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(GuestHeader);
