import config from "./config";

export const convertCurrency = (number) => {
  return "$" + parseFloat(number).toFixed(2).toLocaleString("en-US");
};

export const getRemainToday = (timestamp) => { 
  return getRemainTime((Date.now() / 1000) >> 0, timestamp);
}

const make2Digit = (num) => {
  return (num > 9 ? "" : "0") + num;
}

export const getRemainTime = (timestamp1, timestamp2) => {

  if (timestamp1 >= timestamp2) {
    return "";
  }

  let diff = timestamp2 - timestamp1;

  let day = (diff / 86400) >> 0;
  diff = diff % 86400;

  let hr = (diff / 3600) >> 0;
  diff = diff % 3600;

  let min = (diff / 60) >> 0;
  let sec = diff % 60;

  
  var str = "";
  if (day > 0) {
    str = day + "d ";
  }

  if (hr > 0 || day > 0) {
    str += hr + ":"; 
  }

  str += make2Digit(min) + ":" + make2Digit(sec);

  return str;
}

export const getDiffTime = (timestamp) => {
  const now = (Date.now() / 1000) >> 0;

  let diff = now - timestamp;

  let day = Math.round(diff / 86400);
  if (day > 0) {
    return day + "d";
  }

  diff = diff % 86400;

  let hr = Math.round(diff / 3600);
  if (hr > 0) {
    return hr + "h";
  }

  diff = diff % 3600;
  let min = Math.round(diff / 60);
  if (min > 0) {
    return min + "m";
  }
  let sec = diff % 60;
  return sec + "s";
}

export const getImageUri = (link) => {
  return config.IMAGE_URL + encodeURI(link);
};
