let config = {
  // API_URL: "https://api.sealedbids.com",
  // API_URL: "http://ec2-3-237-235-179.compute-1.amazonaws.com:8080",
  API_URL : "https://testnet-api.sealedbids.com/",
  AUTH_URL: "https://sealedbids.auth.us-east-2.amazoncognito.com/",
  AUTH_CLIENT_ID: "2299infkq1h87h70adejvmna74", //test.sealedbids.com
  // AUTH_CLIENT_ID:"21jap1flfn903hsa69sldfasr8", // test test-sealedbids us-east -2 not sure what this is for
  AUTH_SCOPES: "aws.cognito.signin.user.admin+email+openid+phone+profile",
  AUTH_REDIRECT_URL: "https://test.sealedbids.com",
  // AUTH_REDIRECT_URL: "http://localhost:3000",
  STRIPE_KEY:
    "pk_test_51Jr1R7JeVHDJJJPjjmOyLybpxU8xwweugSHD30jEjZ3C8KrShA4bChfhEYvILIw3cwLeuBNwurMX6M1QqZwyokn000ieE2T7Ya",
  // STRIPE_KEY:
  //   "pk_test_51KjoPeCCeisEx0SZe4IXB41valS08Qa7hF9lPwOHkf0XyV0VttcT7nNLfapRO6ZGuevlPlQlXQLyyW49fR1YKYhd001WwSRkuI", //evgeniy stripe account
  IMAGE_URL: "https://s3.amazonaws.com/sealedbids.com/"
};

let dev_overrides = {
  API_URL: "http://localhost:3001",
  // API_URL: "http://ec2-3-237-235-179.compute-1.amazonaws.com:8080",
  // API_URL : "https://testnet-api.sealedbids.com/",
  // AUTH_REDIRECT_URL: "http://localhost:3000",
  AUTH_URL: "https://sealedbids.auth.us-east-1.amazoncognito.com/",
  AUTH_CLIENT_ID: "36vtuejrddj90e1ekc9kuillbe", //sandbox
  AUTH_SCOPES: "aws.cognito.signin.user.admin+email+openid+phone+profile",
  // AUTH_REDIRECT_URL: "https://test.sealedbids.com",
  AUTH_REDIRECT_URL: "http://localhost:3000",
};

let qa_overrides = {
  AUTH_REDIRECT_URL: "http://localhost:3001",
  // AUTH_REDIRECT_URL: "https://test.sealedbids.com",
};

let NODE_ENV = "PROD";
if (NODE_ENV === "DEV") {
  config = { ...config, ...dev_overrides };
} else if (NODE_ENV === "QA") {
  config = { ...config, ...qa_overrides };
}

console.info("%cCONFIG %o", "color: orange", config);
// eslint-disable-next-line import/no-anonymous-default-export
export default { ...config, dev: NODE_ENV === "DEV" };
