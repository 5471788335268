import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  Elements,
} from "@stripe/react-stripe-js";

import "./styles.scss";
import CardForm from "../../register/cardForm";

const CardEditModal = ({ isOpen, onUpdate, onCancel, myStripe, customer }) => {
  const onHandleSubmit = (formData) => {
    console.log(formData);

    if (onUpdate) {
      onUpdate({});
    }
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title text-center">Edit Payment</p>
      </div>
    );
  };

  const renderElement = () => {
    return (
      <Elements stripe={myStripe}>
        <CardForm
          customer={customer}
          handlePrev={onCancel}
          handleSubmit={onHandleSubmit}
          prevText="Cancel"
          nextText="Update"
        />
      </Elements>
    );
  };

  return (
    <div className="card-edit">
      <Modal
        className="card-edit-modal"
        isOpen={isOpen}
        toggle={onCancel}
        size="lg"
        backdrop={true}
        shouldCloseOnOverlayClick={true}
      >
        {renderTitle()}
        {renderElement()}
      </Modal>
    </div>
  );
};

export default CardEditModal;
