import React, { useEffect, useState } from "react";
import { dummyProductArray, FilterArray } from "../../../consts";
import ProductThumb from "../productThumb";

import "./styles.scss";
import { Form } from "react-final-form";
import { Field } from "react-final-form";
import { Checkbox, Input } from "../../ui";
import Loader from "../../loader";
import { get_listings } from "../../../utils/listings";
import { showError } from "../../../utils";
import { useNavigate } from "react-router";

function ProductList(props) {
  const navigate = useNavigate();

  const LIMIT_SIZE = 12;
  const [filters, setFilters] = useState([]);
  const [products, setProducts] = useState([]);
  const [productFilters, setProductFilters] = useState([]);

  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoadMore, setLoadMore] = useState(false);

  useEffect(() => {
    setFilters(FilterArray);
    getProduct();
  }, []);

  useEffect(() => {
    let filterArray = [...FilterArray];

    filterArray.forEach((value, index, array) => {
      let res = products.filter(
        ({ price }) => price >= value.low && price <= value.high
      );

      array[index].count = res.length;
    });

    setFilters(filterArray);
    doFilter(filterArray);
  }, [products]);

  async function fetchItems(start, handler) {
    try {
      const { total, data } = await get_listings(start, LIMIT_SIZE, 0, true);
      console.log("response:", data);

      const results = data
        ? data.map((item) => {
            item.price = parseFloat(item.price);
            return item;
          })
        : [];
      if (handler) {
        handler(parseInt(total), results);
      }
    } catch (e) {
      console.log(e);
      showError();
      if (handler) {
        handler(0, []);
      }
    }
  }

  async function getProduct() {
    if (isSubmitting) {
      return;
    }
    setSubmitting(true);

    fetchItems(0, (total, data) => {
      setLoadMore(data.length === LIMIT_SIZE);

      setProducts(data);
      setProductFilters(data);

      setSubmitting(false);
    });
  }

  async function loadMoreProducts() {
    if (isSubmitting) {
      return;
    }
    setSubmitting(true);

    fetchItems(products.length, (total, data) => {
      setLoadMore(data.length === LIMIT_SIZE);

      if (data.length > 0) {
        let results = [...products, ...data];

        setProducts(results);
        setProductFilters(results);
      }

      setSubmitting(false);
    });
  }

  const doFilter = (list) => {
    let isAllSelected = true;

    let results = [];

    list.forEach(({ low, high, isSelected }) => {
      if (isSelected) {
        isAllSelected = false;

        let res = products.filter(({ price }) => price >= low && price < high);

        results = [...results, ...res];
      }
    });

    if (isAllSelected) {
      setProductFilters(products);
    } else {
      setProductFilters(results);
    }
  };

  const handleFilterItem = (idx) => {
    let list = [...filters];
    list[idx].isSelected = !list[idx].isSelected;
    setFilters(list);
    doFilter(list);
  };

  const onHandleSubmit = (formData) => {
    setSubmitting(true);
  };

  const handleLoadMore = () => {
    loadMoreProducts();
  };

  const handleItem = (item) => {
    navigate(`/sale/${item.listing_id}`);
  };

  const renderSearch = () => {
    const list = filters.filter(({ isSelected }) => isSelected);

    return (
      <ul className="product-list-search nav my-30">
        {list.map(({ title, count }, idx) => (
          <li key={"total" + idx} className="nav-item">
            {`${title} (${count})`} <i className="fa fa-times-circle" />
          </li>
        ))}
      </ul>
    );
  };

  const renderFilter = () => {
    return (
      <div className="product-list-filter w-full lg-w-1-3 md-w-1-4 mb-30 md-mb-0">
        <Form onSubmit={onHandleSubmit}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="filter-form">
              {/* <div className="form-group">
                <i className="fa fa-search custom-icon" />
                <Field
                  className="search-input"
                  type="text"
                  name="search"
                  placeholder="Search"
                  component={Input}
                />
              </div> */}

              <div className="form-group price-group">PRICE</div>

              <div className="product-filter">
                {filters.map(({ title, count, isSelected }, idx) => (
                  <div key={"filter-" + idx} className="form-group">
                    <Field
                      type="checkbox"
                      name={`product-filter-option-${idx}`}
                      checked={isSelected}
                      component={Checkbox}
                      onClick={() => handleFilterItem(idx)}
                    >
                      {title} ({count})
                    </Field>
                  </div>
                ))}
              </div>

              {/* <div className="price-input-group flex flex-jc my-10">
                <div className="form-group w-4-5">
                  <i className="fa fa-dollar-sign custom-icon" />
                  <Field
                    className="price-input"
                    type="text"
                    name="priceLow"
                    placeholder="Low"
                    component={Input}
                  />
                </div>
                <div className="hypen">–</div>
                <div className="form-group w-4-5">
                  <i className="fa fa-dollar-sign custom-icon" />
                  <Field
                    className="price-input"
                    type="text"
                    name="priceHight"
                    placeholder="High"
                    component={Input}
                  />
                </div>
              </div> */}

              {/* <div className="form-group">
                <div className="w-full text-center">
                  <button
                    className="btn-login"
                    type="submit"
                    disabled={isSubmitting}
                  ></button>
                </div>
              </div> */}
            </form>
          )}
        </Form>
      </div>
    );
  };

  const renderResults = () => {
    let total = productFilters.length;

    return (
      <div className="product-list-results w-full lg-w-2-3 md-w-3-4 my-10">
        <div className="results-count">{total} Items found for</div>
        <div className="results-list">
          {productFilters.length > 0 ? (
            productFilters.map((product, idx) => (
              <div
                key={"results" + idx}
                className="results-item w-full lg-w-1-2 md-w-1-3"
                onClick={() => {
                  handleItem(product);
                }}
              >
                <ProductThumb {...product} />
              </div>
            ))
          ) : (
            <div className="w-full">
              <div key="results_empty" className="results-empty">
                NO RESULTS
              </div>
            </div>
          )}
        </div>
        {isLoadMore && (
          <div className="view-more">
            <button onClick={handleLoadMore}>View More Items</button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="product-list-form lg-px-10 md-px-20 sm-px-0">
      {renderSearch()}

      <div className="flex flex-wrap">
        {renderFilter()}
        {renderResults()}
      </div>
      {isSubmitting && <Loader />}
    </div>
  );
}

export default ProductList;
