import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import Modal from "react-modal";
import { renderGroup, renderInputField, renderSelect } from "../../../consts";
import { Country, State } from "country-state-city";

import "./styles.scss";

const ShippingEditModal = ({ isOpen, onUpdate, onCancel, shipping }) => {
  const [formValues, setFormValues] = useState({});

  const [country, setCountry] = useState();
  const [state, setState] = useState();

  const countries = Country.getAllCountries();

  const updatedCountries = countries.map((c) => ({
    label: c.name,
    value: c.isoCode,
    ...c,
  }));
  const updatedStates = (countryId) =>
    State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.isoCode,
      ...state,
    }));

  useEffect(() => {
    if (shipping) {
      const { country: c, state: s } = shipping;

      if (c) {
        let found = updatedCountries.filter(({ isoCode }) => isoCode === c);
        if (found && found.length > 0) {
          setCountry(found[0]);

          if (s) {
            found = updatedStates(c).filter(({ isoCode }) => isoCode === s);
            if (found && found.length > 0) {
              setState(found[0]);
            }
          }
        }
      }
      setFormValues(shipping);
    }
  }, [shipping]);

  const onHandleSubmit = (formData) => {
    console.log(formData);
    let values = {
      country: country ? country.value : null,
      state: state ? state.value : null,
      city: formData.city,
      postal_code: formData.postal_code,
      line1: formData.line1,
    };

    if (onUpdate) {
      onUpdate(values);
    }
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title text-center">Edit Shipping Address</p>
      </div>
    );
  };
  const renderBody = () => {
    return (
      <>
        {renderGroup(
          <div className="w-full">
            {renderSelect(
              "country",
              updatedCountries,
              country,
              (value) => {
                console.log(value);
                setCountry(value);
                setState(null);
              },
              "Choose country"
            )}
          </div>,
          <div className="w-full">
            {renderSelect(
              "state",
              updatedStates(country ? country.value : null),
              state,
              (value) => {
                setState(value);
              },
              "Choose state"
            )}
          </div>
        )}

        {renderGroup(renderInputField("line1", "Street address"))}

        {renderGroup(
          renderInputField("city", "City"),
          renderInputField("postal_code", "Zip Code")
        )}
      </>
    );
  };

  const renderForm = () => {
    return (
      <Form onSubmit={onHandleSubmit} initialValues={formValues}>
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className="form-content">
            {renderBody(values)}
            <div className="edit-buttons">
              {renderGroup(
                <button
                  className="btn-update"
                  type="submit"
                  disabled={submitting}
                >
                  <span>Update</span>
                </button>,
                <button
                  className="btn-cancel"
                  type="button"
                  disabled={submitting}
                  onClick={onCancel}
                >
                  <span>Cancel</span>
                </button>
              )}
            </div>
          </form>
        )}
      </Form>
    );
  };

  return (
    <div className="shipping-edit">
      <Modal
        className="shipping-edit-modal"
        isOpen={isOpen}
        toggle={onCancel}
        size="lg"
        backdrop={true}
        shouldCloseOnOverlayClick={true}
      >
        {renderTitle()}
        {renderForm()}
      </Modal>
    </div>
  );
};

export default ShippingEditModal;
