
export const NAV_CATEGORY_ITEMS = [
  { url: "category=watch", title: "Watches" },
  { url: "category=bracelet", title: "BraceLets" },
  { url: "category=earring", title: "EarRings" },
  { url: "category=ring", title: "Rings" },
  { url: "category=necklace", title: "Necklaces" },
];

export const NAV_CONFIG = [
  
  { url: "/list?gender=male", title: "For Him", tag: "male", items: NAV_CATEGORY_ITEMS },
  { url: "/list?gender=female", title: "For Her", tag: "female", items: NAV_CATEGORY_ITEMS },
  // { url: "/sale/1", title: "Sale" },
  // { url: "/dashboard", title: "Portal" },
  // { url: "/list", title: "List" },
  // { url: "/notice/confirm-email", title: "Confirm Email" },
  // { url: "/notice/reset-password", title: "Reset Password" },
  // { url: "/notice/be-on-list", title: "You Are On the list" },
  // { url: "/notice/submit-bid", title: "You Submitted a bid" },

  { url: "/about", title: "About" },
];

export const dummyHotProductArray = [
  {
    link: "./img/main/img_hot1.png",
    price: 1099.99,
    description: "Diamond Anniversary Band 5/8 ct tw Round-cut 14K White Gold",
  },
  {
    link: "./img/main/img_hot2.png",
    price: 1399.99,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
  {
    link: "./img/main/img_hot3.png",
    price: 1799.99,
    description: "Men's Black Diamond Link Bracelet 1-1/2 ct tw Sterling Silver",
  },
  {
    link: "./img/main/img_hot1.png",
    price: 1099.99,
    description: "Diamond Anniversary Band 5/8 ct tw Round-cut 14K White Gold",
  },
  {
    link: "./img/main/img_hot2.png",
    price: 1399.99,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
  {
    link: "./img/main/img_hot3.png",
    price: 1799.99,
    description: "Men's Black Diamond Link Bracelet 1-1/2 ct tw Sterling Silver",
  },
];

export const CategoryArray = [
  { url: "/?category=watch", title: "Watches" },
  { url: "/?category=bracelet", title: "BraceLets" },
  { url: "/?category=earring", title: "EarRings" },
  { url: "/?category=ring", title: "Rings" },
  { url: "/?category=necklace", title: "Necklaces" },
];

export const MaterialArray = [
  { url: "/?category=gold", title: "Gold" },
  { url: "/?category=silver", title: "Silver" },
  { url: "/?category=whitegold", title: "White Gold" },
  { url: "/?category=pearl", title: "Pearl" },
  { url: "/?category=diamond", title: "Diamond" },
];

export const dummyJewelryArray = [
  { link: "./img/main/bracelet.png", title: "Bracelets" },
  { link: "./img/main/earring.png", title: "Earrings" },
  { link: "./img/main/ring.png", title: "Rings" },
  { link: "./img/main/necklace.png", title: "Necklaces" },
];

export const FilterArray = [
  { title: "Under $99", count: 12, isSelected: false, low: 0, high: 99 },
  { title: "$100-$250", count: 82, isSelected: false, low: 100, high: 250 },
  { title: "$251-$500", count: 255, isSelected: true, low: 251, high: 500 },
  { title: "$501-$999", count: 141, isSelected: true, low: 501, high: 999 },
  { title: "$1,000-$2,500", count: 255, isSelected: false, low: 1000, high: 2500 },
  { title: "$2,501-$5,000", count: 120, isSelected: false, low: 2501, high: 5000 },
  { title: "Over $5000", count: 34, isSelected: false, low: 5000, high: 100000000 },
];

export const dummyProductArray = [
  {
    link: "./img/main/ring.png",
    price: 525.0,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
  {
    link: "./img/main/ring.png",
    price: 525.0,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
  {
    link: "./img/main/necklace.png",
    price: 1499.99,
    description: "Men's Black Diamond Link Bracelet 1-1/2 ct tw Sterling Silver",
  },
  {
    link: "./img/main/watch.png",
    price: 525.0,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },

  {
    link: "./img/main/earring.png",
    price: 525.0,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
  {
    link: "./img/main/earring.png",
    price: 525.0,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
  {
    link: "./img/main/bracelet.png",
    price: 1499.99,
    description: "Men's Black Diamond Link Bracelet 1-1/2 ct tw Sterling Silver",
  },
  {
    link: "./img/main/necklace1.png",
    price: 525.0,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
];

export const dummyViewMoreArray = [
  {
    link: "./img/main/ring2.png",
    price: 1499.99,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
  {
    link: "./img/main/ring.png",
    price: 525.0,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
  {
    link: "./img/main/ring3.png",
    price: 1299.99,
    description: "Men's Black Diamond Link Bracelet 1-1/2 ct tw Sterling Silver",
  },
  {
    link: "./img/main/ring4.png",
    price: 1399.99,
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
  },
];

export const PORTAL_MENU = {
  products: [
    // { url: "/products/current", title: "Current Bids" },
    // { url: "/products/previous", title: "Previous Items" },
    // { url: "/products/completed", title: "Completed, not shipped items" },
    // { url: "/products/closed", title: "Closed deals" },
    // { url: "/products/add", title: "Add new item" },
    { url: "/products/open", title: "Current Open Bids" },
    { url: "/products/past", title: "Past Bids" },
    { url: "/products/add", title: "Add new item" },
  ],
  profile: [
    { url: "/profile#info", title: "Profile Information" },
    { url: "/profile#shipping", title: "Shipping Address" },
    { url: "/profile#payment", title: "Payment Method" },
  ],
  other: [
    { url: "/about", title: "Questions & Answers" },
    { url: "/help", title: "Help" },
  ],
};

export const dummyBidArray = [
  {
    name: "John Doe",
    mail: "johndoe@gmail.com",
    phone: "+380 980123456",
    time: 1647711838,
    bidPrice: 5555.0,
  },
  {
    name: "Alex T",
    mail: "alex@gmail.com",
    phone: "+380 970325476",
    time: 1647710730,
    bidPrice: 4555.0,
  },
  {
    name: "David P",
    mail: "david@gmail.com",
    phone: "+380 980135456",
    time: 1647711130,
    bidPrice: 3555.0,
  },
];

export const dummyCurrentItemArray = [
  {
    link: "./img/main/ring2.png",
    description: "Diamond Anniversary Band 5/8 ct tw Round-cut 14K White Gold",
    endTime: ((Date.now() / 1000) >> 0) + 4000 + Math.round(300 * Math.random()),
    bidList: dummyBidArray,
  },
  {
    link: "./img/main/ring.png",
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
    endTime: ((Date.now() / 1000) >> 0) + 3000 + Math.round(600 * Math.random()),
    bidList: dummyBidArray,
  },
  {
    link: "./img/main/ring3.png",
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
    endTime: ((Date.now() / 1000) >> 0) + 3500 + Math.round(500 * Math.random()),
    bidList: dummyBidArray,
  },
];

export const dummyPrevItemArray = [
  {
    link: "./img/main/ring2.png",
    description: "Diamond Anniversary Band 5/8 ct tw Round-cut 14K White Gold",
    endTime: ((Date.now() / 1000) >> 0) - 1000 - Math.round(300 * Math.random()),
    bidList: dummyBidArray,
    buyer: dummyBidArray[0]
  },
  {
    link: "./img/main/ring.png",
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
    endTime: ((Date.now() / 1000) >> 0) - 2000 - Math.round(600 * Math.random()),
    bidList: dummyBidArray,
    buyer: dummyBidArray[1]
  },
  {
    link: "./img/main/ring3.png",
    description: "1/20 Carat t.w. Diamond Engravable Wedding Band",
    endTime: ((Date.now() / 1000) >> 0) - 1500 - Math.round(500 * Math.random()),
    bidList: dummyBidArray,
    buyer: dummyBidArray[2]
  },
];
