import React, { useEffect, useState } from "react";
import {
  Clock,
  convertCurrency,
  getImageUri,
  getRemainToday,
  Placeholder,
} from "../../../consts";
import { checkAuth, showSuccessToast } from "../../../utils";
import {
  get_bids,
  get_payment_customer,
  get_payment_wins,
  post_bid,
} from "../../../utils/listings";

import Loader from "../../loader";
import IncreaseBidModal from "../increaseBidModal";

import "./styles.scss";

function OpenBids(props) {
  const [bidItems, setBidItems] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [isLoading, setLoading] = useState(false);
  const [time, setTime] = useState((Date.now() / 1000) >> 0);

  useEffect(() => {
    setLoading(true);
    getBids();
  }, []);

  useEffect(() => {
    if (bidItems.length > 0) {
      let timeout = setTimeout(function () {
        setTime((Date.now() / 1000) >> 0);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [bidItems, time]);

  async function getBids() {
    try {
      let bids = await get_bids(false);
      setBidItems(bids);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setBidItems([]);
      checkAuth(e);
    }
  }

  async function postBid(listId, amount) {
    let customer;
    try {
      customer = await get_payment_customer();
      if (!customer.payment_methods) {
        console.log("customer", customer);
      } else {
        let res = await post_bid(listId, amount);
        console.log("bid results %o", res);

        showSuccessToast('Your bid has been posted successfully.', {
          position: 'top-center',
          autoClose: 2000,
          pauseOnHover: false,
          toastClassName: 'update-modal',
          onClose: () => {
            window.location.href = "";
          },
        });
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      checkAuth(e);
    }
  }

  const onToggleModal = () => {
    if (selectedIndex !== -1) {
      setSelectedIndex(-1);
    }
  };

  const onPlaceBid = (bidPrice) => {
    console.log("bid", bidPrice);
    setLoading(true);

    postBid(bidItems[selectedIndex].listing_id, bidPrice);
    setSelectedIndex(-1);
  };

  const onIncreaseBid = (idx) => {
    setSelectedIndex(idx);
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title px-20">Current Open Bids</p>
      </div>
    );
  };

  const renderRow = (bid, index) => {
    const { name, description, image_keys, exp_time, amount } = bid;

    const expired = (new Date(exp_time).getTime() / 1000) >> 0;
    let remain = getRemainToday(expired);
    let isEnd = false;
    if (remain === "") {
      remain = "Finished";
      isEnd = true;
    } else {
      remain += " remaining";
    }

    return (
      <div
        key={"row" + index}
        className="row-item px-10 py-10 md-px-10 sm-py-20 md-py-20"
      >
        <div className="product flex w-full sm-w-1-2 md-w-1-2 pb-20 sm-pb-0 md-pb-0">
          <div className="left">
            <img
              src={getImageUri(image_keys[0])}
              alt=""
              onError={({ target }) => {
                target.onError = null;
                target.src = Placeholder;
              }}
            />
          </div>
          <div className="right">
            <div className="detail">{name}</div>
            <div className="status">
              <Clock /> <span>{remain}</span>
            </div>
          </div>
        </div>

        <div className="other flex w-full sm-w-1-2 md-w-1-2">
          <div className="price w-3-5">
            <div className="price-title">Your Current Bid</div>
            <div className="price-value">{convertCurrency(amount)}</div>
            <div
              className="price-bid"
              onClick={() => {
                onIncreaseBid(index);
              }}
            >
              Increase Bid
            </div>
          </div>

          <div className="more w-2-5">
            <div className="more-detail">Open</div>
          </div>
        </div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="results-list w-full my-10">
        {bidItems.length > 0 ? (
          bidItems.map((item, idx) => renderRow(item, idx))
        ) : (
          <div className="w-full">
            <div className="text-center">
              NO RESULTS
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="open-bids-form lg-px-10 md-px-20 sm-px-0">
        {renderTitle()}
        {renderResults()}
        {isLoading && <Loader />}
      </div>
      {selectedIndex !== -1 && (
        <IncreaseBidModal
          isOpen={selectedIndex !== -1}
          onBid={onPlaceBid}
          onCancel={onToggleModal}
          data={bidItems[selectedIndex]}
        />
      )}
    </>
  );
}

export default OpenBids;
