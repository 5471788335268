import React, { useRef, useState } from "react";

import "./styles.scss";

import uploadImg from "../../../assets/img/add-image.png";

const DropFileInput = ({ type, isValid, onFileChange }) => {
  const wrapperRef = useRef(null);

  const [file, setFile] = useState(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setFile(newFile);
      if (onFileChange) {
        onFileChange(newFile);
      }
    }
  };

  const fileRemove = (f) => {
    setFile(null);
    if (onFileChange) {
      onFileChange(null);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={"drop-file-input" + (type === "0" ? " small" : "") + (isValid && !file ? " empty" : "")}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {file === null ? (
        <>
          <div className="drop-file-input__label">
            <img src={uploadImg} alt="" />
            <p>
              Drop your image here, or select <span>click to browse</span>
            </p>
          </div>
          <input type="file" value="" accept="image/*" onChange={onFileDrop} />
        </>
      ) : (
        <div className="drop-file-preview">
          <div className="drop-file-preview__item">
            <div className="drop-file-preview__item__info">
              <img src={URL.createObjectURL(file)} width={"100%"} alt="" />
            </div>

            <i
              className="drop-file-preview__item__del fas fa-trash"
              onClick={() => fileRemove(file)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DropFileInput;
