import React, { useEffect, useState } from "react";
import {
  Past,
  convertCurrency,
  getImageUri,
  Placeholder,
} from "../../../consts";
import { checkAuth } from "../../../utils";
import { get_bids } from "../../../utils/listings";

import Loader from "../../loader";

import "./styles.scss";

function PastBids(props) {
  const [bidItems, setBidItems] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [isLoading, setLoading] = useState(false);
  const [time, setTime] = useState((Date.now() / 1000) >> 0);

  useEffect(() => {
    setLoading(true);
    getBids();
  }, []);

  useEffect(() => {
    if (bidItems.length > 0) {
      let timeout = setTimeout(function () {
        setTime((Date.now() / 1000) >> 0);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [bidItems, time]);

  async function getBids() {
    try {
      let bids = await get_bids(true);
      setBidItems([...bids]);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      checkAuth(e);
    }
  }

  const onToggleModal = () => {
    if (selectedIndex !== -1) {
      setSelectedIndex(-1);
    }
  };

  const onViewBids = (idx) => {
    //
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title px-20">Past Bids</p>
      </div>
    );
  };

  const renderRow = (bid, index) => {
    const {
      name,
      description,
      image_keys,
      amount,
      highest_bid,
      bid_count,
      win_ids,
      bidder,
    } = bid;

    let remain = "Finished";

    let success = false;
    if (win_ids && win_ids[0]) {
      success = bidder === win_ids[0];
    }

    return (
      <div
        key={"row" + index}
        className="row-item px-10 py-10 md-px-10 sm-py-20 md-py-20"
      >
        <div className="product flex w-full sm-w-1-2 md-w-1-2 pb-20 sm-pb-0 md-pb-0">
          <div className="left">
            <img
              src={getImageUri(image_keys[0])}
              alt=""
              onError={({ target }) => {
                target.onError = null;
                target.src = Placeholder;
              }}
            />
          </div>
          <div className="right">
            <div className="detail">{name}</div>
            <div className="detail">{description}</div>
            <div className="status">
              <Past /> <span>{remain}</span>
            </div>
          </div>
        </div>

        <div className="other flex w-full sm-w-1-2 md-w-1-2">
          <div className="price w-3-5">
            {success ? (
              <>
                <div className="price-title">Your Bid</div>
                <div className="price-value">
                  {convertCurrency(amount)}
                  <span className="paid">
                    Paid <i className="fa fa-check" />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="flex price-two">
                  <div className="left">
                    <div className="price-title">Your Bid</div>
                    <div className="price-value text-line">
                      {convertCurrency(amount)}
                    </div>
                  </div>
                  <div className="middle">
                    <div className="line" />
                  </div>
                  <div className="right">
                    <div className="price-title">Highest Bid</div>
                    <div className="price-value">
                      {convertCurrency(highest_bid)}
                    </div>
                  </div>
                </div>
                <div
                  className="price-bid"
                  onClick={() => {
                    onViewBids(index);
                  }}
                >
                  View {bid_count} other bids
                </div>
              </>
            )}
          </div>

          <div className="more w-2-5">
            {success ? (
              <div className="more-done">Successful</div>
            ) : (
              <div className="more-failed">Unsuccessful</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="results-list w-full my-10">
        {bidItems.length > 0 ? (
          bidItems.map((item, idx) => renderRow(item, idx))
        ) : (
          <div className="w-full">
            <div className="text-center">NO RESULTS</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="past-bids-form lg-px-10 md-px-20 sm-px-0">
      {renderTitle()}
      {renderResults()}
      {isLoading && <Loader />}
    </div>
  );
}

export default PastBids;
