import config from "../consts/config";

export function signIn() {
  const { AUTH_URL, AUTH_CLIENT_ID, AUTH_SCOPES, AUTH_REDIRECT_URL } = config;
  const login_url = `${AUTH_URL}login?client_id=${AUTH_CLIENT_ID}&response_type=token&scope=${AUTH_SCOPES}&redirect_uri=${AUTH_REDIRECT_URL}`;
  console.log("attempting to go to login URL %o", login_url);
  window.location.href = login_url;
}

export function signUp() {
  const { AUTH_URL, AUTH_CLIENT_ID, AUTH_SCOPES, AUTH_REDIRECT_URL } = config;
  const signup_url = `${AUTH_URL}signup?client_id=${AUTH_CLIENT_ID}&response_type=token&scope=${AUTH_SCOPES}&redirect_uri=${AUTH_REDIRECT_URL}`;
  console.log("attempting to go to login URL %o", signup_url);
  window.location.href = signup_url;
}

export function Logout() {
  const { AUTH_URL, AUTH_CLIENT_ID, AUTH_SCOPES, AUTH_REDIRECT_URL } = config;
  const logout_url = `${AUTH_URL}logout?client_id=${AUTH_CLIENT_ID}&response_type=token&scope=${AUTH_SCOPES}&redirect_uri=${AUTH_REDIRECT_URL}`;
  console.log("attempting to go to logout URL %o", logout_url);
  window.location.href = logout_url;
}
