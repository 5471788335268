import global from "./global";
import account from "./account";

function rootReducer(state, action) {
  return {
    global: global(state.global, action),
    account: account(state.account, action),
  };
}

export default rootReducer;
