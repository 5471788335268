import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import CreatableSelect from "react-select/creatable";

import Loader from "../../loader";
import { Textarea, DropFileInput } from "../../ui";
import {
  renderGroup,
  renderInput,
  renderNumber,
  renderSelect,
} from "../../../consts";

import "./styles.scss";
import { post_listing } from "../../../utils/listings";
import { post_item, post_item_images } from "../../../utils/items";
import { showError } from './../../../utils/index';
import { duration } from "moment";

const categoryList = [
  { value: "category1", label: "Category1" },
  { value: "category2", label: "Category2" },
];

const tagsList = [
  { value: "Diamond", label: "Diamond" },
  { value: "Ring", label: "Ring" },
  { value: "Watch", label: "Watch" },
  { value: "EarRing", label: "EarRing" },
];

const stoneTypeList = [
  { value: "Gold", label: "Gold" },
  { value: "Silver", label: "Silver" },
];

const addSizeList = [
  { value: "size1", label: "10x10" },
  { value: "size2", label: "15x15" },
];

const metalTypeList = [
  { value: "metal1", label: "Metal" },
  { value: "metal2", label: "Metal2" },
];

const weightList = [
  { value: "weigh1", label: "10g" },
  { value: "weigh2", label: "20g" },
];

const genderList = [
  { value: "m", label: "Male" },
  { value: "f", label: "Female" },
  { value: "b", label: "Both" },
];

const purchaseList = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

const AddNewItem = (props) => {
  const Duration = 3600 * 72;
  const { errors } = props;
  const [isSubmitting, setSubmitting] = useState(false);

  const [isValid, setValid] = useState(false);
  const [category, setCategory] = useState(null);
  const [tags, setTags] = useState(null);
  const [stoneType, setStoneType] = useState(null);
  const [addSize, setAddSize] = useState(null);
  const [metalType, setMetalType] = useState(null);
  const [weight, setWeight] = useState(null);
  const [gender, setGender] = useState(null);
  const [purchaseType, setPurchaseType] = useState(null);
  const [durationTime, setDurationTime] = useState(null);

  const [imageFile1, setImageFile1] = useState(null);
  const [imageFile2, setImageFile2] = useState(null);
  const [imageFile3, setImageFile3] = useState(null);
  const [imageFile4, setImageFile4] = useState(null);

  useEffect(() => {
    if (errors) {
      setSubmitting(false);
    }
  }, [errors]);

  async function onHandleSubmit(formData) {
    setValid(true);
    // setSubmitting(true);
    const { name, price, quantity, description, msrp, durationTime } = formData;

    if (!name || !price || !quantity || !description || !category || !tags) {
      return;
    }
    if (!stoneType || !addSize || !metalType || !weight || !gender || !purchaseType || !msrp || !durationTime) {
      return;
    }
    if (!imageFile1 || !imageFile2 || !imageFile3 || !imageFile4) {
      return;
    }

    if (isSubmitting) {
      return;
    }
    setSubmitting(true);

    let tag = "";
    tags.forEach(({ value, label }) => {
      tag = tag + label + ",";
    });

    let values = {
      name: name,
      price: price,
      msrp: msrp,
      quantity: quantity,
      category: category.label,
      tags: tag,
      stone: stoneType.label,
      size: addSize.label,
      metal: metalType.label,
      weight: weight.label,
      gender: gender.value,
      durationTime: durationTime,
      purchaseType: purchaseType.value,
      description: description,
    };

    try {
      console.log('posting items', values);
      let item = await post_item(values);
      console.log("post items:", item);
      console.log(values);

      let res = await post_item_images(item.item_id, [
        imageFile1,
        imageFile2,
        imageFile3,
        imageFile4,
      ]);
      console.log("post images:", res);

      const listing = {
        item_id: item.item_id,
        reserve: item.price,
        auction: values.purchaseType,
        msrp: item.msrp,
        start_time: new Date(),
        // duration: Duration,
        duration: 3600 * values.durationTime,
      };

      res = await post_listing(listing);
      console.log("listing:", res);

      window.location.href = `/notice/be-on-list/${res.listing_id}`;

      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setSubmitting(false);
      showError();
    }
  }

  const changeValueCategory = (value) => {
    setCategory(value);
  };
  const changeValueTags = (value) => {
    setTags(value);
  };
  const changeValueAddSize = (value) => {
    setAddSize(value);
  };
  const changeValueStoneType = (value) => {
    setStoneType(value);
  };
  const changeValueMetalType = (value) => {
    setMetalType(value);
  };
  const changeValueWeight = (value) => {
    setWeight(value);
  };
  const changeValueGender = (value) => {
    setGender(value);
  };

  const changeValuePurchaseType = (value) => {
    setPurchaseType(value);
  };
  

  const handleFile1 = (file) => {
    setImageFile1(file);
  };
  const handleFile2 = (file) => {
    setImageFile2(file);
  };
  const handleFile3 = (file) => {
    setImageFile3(file);
  };
  const handleFile4 = (file) => {
    setImageFile4(file);
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title px-20">Add New Item</p>

        <div className="line" />
      </div>
    );
  };

  const renderError = (cond, msg) => {
    return <div className="error-msg">{isValid && cond ? msg : "\u00A0"}</div>;
  };

  const renderForm = () => {
    return (
      <Form onSubmit={onHandleSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className="add-form">
            {renderGroup(
              <div className="w-full">
                <div className="input-title">Product Name</div>
                {renderInput("name", "Product Name")}
                {renderError(!values.name, "Please input the value.")}
              </div>,
              <div className="w-full">
                <div className="input-title">Category</div>
                {renderSelect(
                  "category",
                  categoryList,
                  category,
                  changeValueCategory,
                  "Choose your category"
                )}
                {renderError(!category, "Please select an item.")}
              </div>
            )}

            {renderGroup(
              <div className="w-full">
                <div className="input-title">Tags</div>
                <CreatableSelect
                  isMulti
                  name="tags"
                  options={tagsList}
                  value={tags}
                  onChange={changeValueTags}
                  className="item-select"
                  classNamePrefix="select"
                  placeholder=""
                />
                {renderError(!tags, "Please select the items.")}
              </div>
            )}

            {renderGroup(
              <div className="w-full">
                <div className="input-title">Add Size</div>
                {renderSelect(
                  "addSize",
                  addSizeList,
                  addSize,
                  changeValueAddSize
                )}
                {renderError(!addSize, "Please select an item.")}
              </div>,
              <div className="w-full">
                <div className="input-title">Stone Type</div>
                {renderSelect(
                  "stoneType",
                  stoneTypeList,
                  stoneType,
                  changeValueStoneType
                )}
                {renderError(!stoneType, "Please select an item.")}
              </div>
            )}

            {renderGroup(
              <div className="w-full">
                <div className="input-title">Metal Type</div>
                {renderSelect(
                  "metalType",
                  metalTypeList,
                  metalType,
                  changeValueMetalType
                )}
                {renderError(!metalType, "Please select an item.")}
              </div>,
              <div className="w-full">
                <div className="input-title">Weight</div>
                {renderSelect("weight", weightList, weight, changeValueWeight)}
                {renderError(!weight, "Please select an item.")}
              </div>
            )}

            {renderGroup(
              <div className="w-full">
                <div className="input-title">Gender</div>
                {renderSelect("gender", genderList, gender, changeValueGender)}
                {renderError(!gender, "Please select an item.")}
              </div>,
              <div className="w-full">
                <div className="input-title">Quantity</div>
                {renderNumber("quantity", "Quantity")}
                {renderError(!values.quantity, "Please input the quantity.")}
              </div>
            )}

            {renderGroup(
              <div className="w-full">
                <div className="input-title">Minimum Sale Reserve Price ($)</div>
                {renderNumber("price", "Enter Minimum Sale Reserve Price")}
                {renderError(!values.price, "Please input the price.")}
              </div>,
              <div className="w-full">
              <div className="input-title">Auction</div>
              {renderSelect("purchase", purchaseList, purchaseType, changeValuePurchaseType)}
              {renderError(!purchaseType, "Please select purchase type.")}
            </div>
            )}
 
            {renderGroup(
              <div className="w-full">
              <div className="input-title">MSRP Price ($)</div>
              {renderNumber("msrp", "Enter MSRP price")}
              {renderError(!values.msrp, "Please input the MSRP price.")}
            </div>,
              <div className="w-full">
                <div className="input-title">Duration (hours)</div>
                {renderNumber("durationTime", "Enter auction duration time")}
                {renderError(!values.durationTime, "Please input the duration time.")}
              </div>
            )}

            {renderGroup(
              <div className="w-full">
                <div className="input-title">Product Description</div>
                <Field
                  type="text"
                  name="description"
                  placeholder="Description"
                  component={Textarea}
                />
                {renderError(
                  !values.description,
                  "Please input the description."
                )}
              </div>
            )}

            <div className="w-full mt-10 md-mt-30">
              <div className="input-title">
                Product Image <span>(You need to add at least 4 images.)</span>
              </div>
            </div>

            <div className="form-group file-input-group">
              <div className="w-full sm-w-2-5 lg-w-2-5 md-w-2-5 mb-10">
                <DropFileInput isValid={isValid} onFileChange={handleFile1} />
              </div>
              <div className="w-full sm-w-2-5 lg-w-2-5 md-w-2-5 mb-10">
                <DropFileInput isValid={isValid} onFileChange={handleFile2} />
              </div>
              <div className="remain-group w-full sm-w-1-5 lg-w-1-5 md-w-1-5">
                <DropFileInput
                  type="0"
                  isValid={isValid}
                  onFileChange={handleFile3}
                />
                <DropFileInput
                  type="0"
                  isValid={isValid}
                  onFileChange={handleFile4}
                />
              </div>
            </div>

            {renderGroup(
              <div className="w-full text-center mb-20">
                <button
                  className="btn-add"
                  type="submit"
                  disabled={isSubmitting}
                >
                  <span>Add Product</span>
                </button>
              </div>
            )}

            {renderGroup(
              <div className="w-full text-center">
                <button
                  className="btn-preview"
                  type="button"
                  disabled={isSubmitting}
                >
                  <span>Item Preview</span>
                </button>
              </div>
            )}
          </form>
        )}
      </Form>
    );
  };

  return (
    <div className="addnew-form lg-px-10 md-px-20 pb-50">
      {renderTitle()}
      {renderForm()}
      {isSubmitting && <Loader />}
    </div>
  );
};

export default AddNewItem;
