import React from "react";
import "./styles.scss";

const ConfirmEmailContent = () => {
  const firstName = "Alex";
  const confirmUrl =
    "https://sealedbids.com/xxxxxx/xxxxx/xxxxxx/xxxxxxxxxxx/x//x////xxx";

  return (
    <div className="confirm-email content px-10 sm-px-30 my-10">
      <div className="sub-title">Confirm your email address</div>

      <div>
        Hi {firstName}, thanks for signing up!
        <br />
        <br />
        Please confirm your account by clicking the button below.
      </div>

      <button className="btn-confirm" type="btn">
        <a href={confirmUrl}>Confirm Email</a>
      </button>

      <div>
        If that doesn’t work copy and paste the following link in your browser:
        <br />
        <span>{confirmUrl}</span>
      </div>

      <div className="py-30">
        Sincerely,
        <br />
        SealedBids team
      </div>
    </div>
  );
};

export default ConfirmEmailContent;
