import React, { Component } from "react";
import { Routes } from "react-router-dom";
import AppRoute from "./appRoute";
import { Route } from "react-router";
import ProductList from "../pages/productList";
import Dashboard from "../components/dashboard";
import NoticePage from "../pages/notice";
import Cart from "../pages/cart";
import Checkout from "../pages/checkout";
import { signIn, signUp } from "../utils/auth";

const Home = React.lazy(() => import("../pages/home"));
const Login = React.lazy(() => import("../pages/login"));
const Register = React.lazy(() => import("../pages/register"));
const About = React.lazy(() => import("../pages/about"));
const ProductInfo = React.lazy(() => import("../pages/productInfo"));
const BuyNowInfo = React.lazy(() => import("../pages/buyNowInfo"));

const route = (component, isPublic, keepGuestLayout) => {
  return (
    <AppRoute
      component={component}
      isPublic={true}
      keepGuestLayout={keepGuestLayout}
    />
  );
};

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={route(Home, true)} />
    <Route path="/about" element={route(About)} />
    <Route path="/login" element={route(signIn, true, true)} />
    <Route path="/signup" element={route(signUp, true, true)} />
    <Route path="/register" element={route(Register, true, true)} />
    <Route path="/sale/:id" element={route(ProductInfo, true)} />
    <Route path="/buy/:id" element={route(BuyNowInfo, true)} />
    <Route path="/list" element={route(ProductList, true)} />

    <Route path="/cart" element={route(Cart, true)} />
    <Route path="/checkout" element={route(Checkout, true)} />

    <Route path="/dashboard" element={route(Dashboard, true)} />
    <Route path="/products/:id" element={route(Dashboard, true)} />
    <Route path="/profile/:id" element={route(Dashboard, true)} />
    <Route path="/profile" element={route(Dashboard, true)} />

    <Route path="/notice/confirm-email" element={route(NoticePage, true, true)} />
    <Route path="/notice/reset-password" element={route(NoticePage, true, true)} />
    <Route path="/notice/be-on-list/:id" element={route(NoticePage, true, true)} />
    <Route path="/notice/submit-bid/:id" element={route(NoticePage, true, true)} />
  </Routes>
);

export default AppRoutes;
