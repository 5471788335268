import http from "./http";

export async function post_listing(data) {
  console.log('post_listing', data)
  try {
    let response = await http.post(`/api/listings`, data);
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function get_listings(start, limit, expired, auction) {
  try {
    let cond = expired ? `&expired=${expired}` : '';
    if (typeof auction !== undefined) {
      cond = cond + auction ? `&auction=${auction}` : '';
    }
    
    let response = await http.get(
      `/api/listings?start=${start}&limit=${limit}${cond}`
    );
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function get_listing(listing_id) {
  try {
    let response = await http.get(`/api/listings/${listing_id}`);
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function put_listing(listing_id, data) {
  try {
    let response = await http.put(`/api/listings/${listing_id}`, data);
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function del_listing(listing_id) {
  try {
    let response = await http.del(`/api/listings/${listing_id}`);
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function create_intent(listing_id, amount) {
  try {
    let response = await http.post(`/api/listings/${listing_id}/intents`, {
      amount: parseInt(parseFloat(amount).toFixed(2)),
      currency: "usd",
    });
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function post_bid(listing_id, amount) {
  try {
    let response = await http.post(`/api/listings/${listing_id}/bids`, {
      amount: parseInt(parseFloat(amount).toFixed(2)),
      currency: "usd",
    });
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function post_purchase(listing_id, amount) {
  try {
    let response = await http.post(`/api/listings/${listing_id}/purchase`, {
      amount: parseInt(parseFloat(amount).toFixed(2)),
      currency: "usd",
    });
    return response.data || null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function get_bids(expired) {
  try {
    let cond = expired ? `?expired=${expired}` : '';
    let response = await http.get(`/api/listings/bids${cond}`);
    return response.data || [];
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function get_bid(bid_id) {
  try {
    let response = await http.get(`/api/listings/bids/${bid_id}`);
    return response.data || [];
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function get_payment_customer() {
  try {
    let response = await http.get(`/api/payments/customer`);
    return response.data || {};
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function put_payment_customer(params) {
  try {
    let response = await http.put(`/api/payments/customer`, params);
    return response.data || {};
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function post_payment_intents() {
  try {
    let response = await http.post(`/api/payments/intents`);
    return response.data || {};
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function get_payment_wins() {
  try {
    let response = await http.get(`/api/payments/wins`);
    return response.data || {};
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}
