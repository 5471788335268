import React from "react";
import { Link } from "react-router-dom";
import { CategoryArray, MaterialArray } from "../../consts";
import withRouter from "../../routes/withRouter";
import "./styles.scss";

const Footer = () => {
  const linkArray = [
    [{ url: "/?gender=male", title: "For Him" }, ...CategoryArray],
    [{ url: "/?gender=female", title: "For Her" }, ...CategoryArray],
    [{ url: "/?gender=female", title: "Material" }, ...MaterialArray],
  ];

  const renderColumn = (list, col) => {
    return (
      <div key={"footer-column" + col} className="footer-column">
        {list.map(({ url, title }, idx) => (
          <Link
            key={`footer-column-${col}_${idx}`}
            className="footer-column-item"
            to={url}
          >
            <div>{title}</div>
          </Link>
        ))}
      </div>
    );
  };

  const renderList = () => {
    return (
      <div className="footer-items">
        {linkArray.map((list, idx) => renderColumn(list, idx))}
        <div className="footer-company">
          © 2021 | SealedBids. All Rigths Reserved.
        </div>
      </div>
    );
  };

  return (
    <div className="footer">
      <div className="footer__inner container">{renderList()}</div>
    </div>
  );
};

export default withRouter(Footer);
