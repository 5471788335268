import React from "react";
import { GuestHeader, UserHeader } from "../components/header";
import Footer from "../components/footer";

export function GuestLayout(props) {
  const { children } = props;
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}

export function UserLayout(props) {
  const { children } = props;
  return (
    <React.Fragment>
      <UserHeader />
      {children}
      <Footer />
    </React.Fragment>
  );
}
