import React, { useEffect, useState } from "react";
import { logout } from "../../../actions/account";
import { useAppDispatch } from "../../../appState";
import { Contact, Finance, Map } from "../../../consts";
import {
  get_payment_customer,
  put_payment_customer,
} from "../../../utils/listings";
import { get_profile, update_profile } from "../../../utils/profile";
import ProfileEditModal from "../profileEditModal";
import { checkAuth, Logout, showError, showSuccessToast } from "./../../../utils";

import "./styles.scss";
import ShippingEditModal from "./../shippingEditModal";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../../consts/config";
import CardEditModal from "../cardEditModal";

const myStripe = loadStripe(config.STRIPE_KEY);

function ProfileForm() {
  const EDIT_PROFILE = 1;
  const EDIT_ADDRESS = 2;
  const EDIT_PAYMENT = 3;
  const EDIT_NONE = 0;

  const dispatch = useAppDispatch();
  const [profile, setProfile] = useState({});
  const [editMode, setEditMode] = useState(EDIT_NONE);
  const [customer, setCustomer] = useState();

  const [shipping, setShipping] = useState({});

  useEffect(() => {
    const str = localStorage.getItem("profile");
    if (str) {
      const profile = JSON.parse(str);
      setProfile(profile);
    } else {
      getProfile();
    }
  }, []);

  useEffect(() => {
    async function customer() {
      try {
        const customer_data = await get_payment_customer();
        console.log("[customer]", customer_data);
        setCustomer(customer_data);

        if (customer_data && customer_data.shipping) {
          setShipping(customer_data.shipping.address);
        }
      } catch (e) {
        console.log(e);
        showError();
      }
    }
    customer();
  }, []);

  async function getProfile() {
    try {
      const res = await get_profile();
      console.log("response:", res);

      const { profile_id, sub, email, first_name, last_name, phone } = res;

      const profile = { profile_id, sub, email, first_name, last_name, phone };

      localStorage.setItem("profile", JSON.stringify(profile));

      setProfile(profile);
    } catch (e) {
      console.log(e);
      checkAuth(e);
    }
  }

  const onLogout = () => {
    localStorage.removeItem("sb_auth_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("profile");
    dispatch(logout());
    Logout();
    window.location.href = "/";
  };

  const onEditProfile = () => {
    setEditMode(EDIT_PROFILE);
  };

  const onEditAddress = () => {
    setEditMode(EDIT_ADDRESS);
  };

  const onEditPayment = () => {
    setEditMode(EDIT_PAYMENT);
  };

  const onUpdate = (values) => {
    if (editMode === EDIT_PROFILE) {
      updateProfile(values);
    } else if (editMode === EDIT_ADDRESS) {
      updateAddress(values);
    } else if (editMode === EDIT_PAYMENT) {
      showSuccessToast('Payment has been updated successfully.', {
        position: 'top-center',
        autoClose: 2000,
        pauseOnHover: false,
        toastClassName: 'update-modal',
        onClose: () => {
          window.location.href = "";
        },
      });
    }
  };

  const onCancel = () => {
    setEditMode(EDIT_NONE);
  };

  async function updateProfile(values) {
    try {
      const res = await update_profile(null, values);
      console.log("response:", res);

      const { profile_id, sub, email, first_name, last_name, phone } = res;

      const profile = { profile_id, sub, email, first_name, last_name, phone };

      localStorage.setItem("profile", JSON.stringify(profile));

      showSuccessToast('Profile has been updated successfully.', {
        position: 'top-center',
        autoClose: 2000,
        pauseOnHover: false,
        toastClassName: 'update-modal',
        onClose: () => {
          window.location.href = "";
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      showError();
      return false;
    }
  }

  async function updateAddress(values) {
    let cardHolder = "";
    if (customer && customer.payment_methods.length > 0) {
      const payment = customer.payment_methods[0];
      cardHolder = payment.billing_details.name || "";
    }

    try {
      const res = await put_payment_customer({
        shipping: { address: values, name: cardHolder || "" },
      });
      console.log(res);
      showSuccessToast('Address has been updated successfully.', {
        position: 'top-center',
        autoClose: 2000,
        pauseOnHover: false,
        toastClassName: 'update-modal',
        onClose: () => {
          window.location.href = "";
        },
      });
    } catch (e) {
      console.log(e);
      showError();
    }
  }

  const renderContent = () => {
    const data = {
      name: `${profile.first_name || ""} ${profile.last_name || ""}`,
      email: profile.email || "",
      phone: profile.phone,
    };

    const { line1, city, state, country, postal_code } = shipping;

    let cardHolder = "";
    let cardNumber = "";
    let expireDate = "";
    if (customer && customer.payment_methods.length > 0) {
      const payment = customer.payment_methods[0];

      cardHolder = payment.billing_details.name || "";
      if (payment.card) {
        cardNumber = "**** **** **** " + (payment.card.last4 || "****");
        expireDate =
          "| Expires " +
          payment.card.exp_month +
          "/" +
          (payment.card.exp_year - 2000);
      }
    }

    return (
      <div className="content w-full my-10">
        <div className="flex title" id="info">
          Account Information
        </div>
        <div className="line" />

        <div className="flex sub-title">Contact Information</div>
        <div className="description">
          <div className="flex first">
            <Contact />
            <div className="value">{data.name}</div>
          </div>

          <div className="flex">
            <div className="value">{data.email}</div>
          </div>

          <div className="flex">
            <div className="value">{data.phone}</div>
          </div>
        </div>

        <div className="flex">
          <button className="link" onClick={onEditProfile}>
            Edit
          </button>

          <button className="link logout" onClick={onLogout}>
            Logout
          </button>
          {/* <button className="link">Change Password</button> */}
        </div>

        <div className="flex title" id="shipping">
          Shipping Address
        </div>
        <div className="line" />

        <div className="flex sub-title">Default Shipping Address</div>

        <div className="description">
          <div className="flex first">
            <div className="value">
              <Map />
              {data.name}, {data.phone}
            </div>
          </div>
          <div className="value">{line1}</div>
          <div className="value">{city || ""}</div>
          <div className="value">{`${state || ""} ${postal_code || ""} ${
            country || ""
          }`}</div>
        </div>

        <div className="flex">
          <button className="link" onClick={onEditAddress}>
            Edit Address
          </button>
        </div>

        <div className="flex title" id="payment">
          Payment Methods
        </div>
        <div className="line" />

        <div className="flex sub-title">
          <Finance />
          Credit/Debit Card
        </div>

        <div className="description">
          <div className="flex">
            <div className="value">{cardHolder}</div>
          </div>
          <div className="flex">
            <div className="value">
              {cardNumber} {expireDate}
            </div>
          </div>
        </div>

        <div className="flex">
          <button className="link" onClick={onEditPayment}>
            Change
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="profile-form">
      {renderContent()}

      {editMode === EDIT_PROFILE && (
        <ProfileEditModal
          isOpen={editMode === EDIT_PROFILE}
          onUpdate={onUpdate}
          onCancel={onCancel}
          profile={profile}
        />
      )}
      {editMode === EDIT_ADDRESS && (
        <ShippingEditModal
          isOpen={editMode === EDIT_ADDRESS}
          onUpdate={onUpdate}
          onCancel={onCancel}
          shipping={shipping}
        />
      )}
      {editMode === EDIT_PAYMENT && (
        <CardEditModal
          isOpen={editMode === EDIT_PAYMENT}
          onUpdate={onUpdate}
          onCancel={onCancel}
          myStripe={myStripe}
          customer={customer}
        />
      )}
    </div>
  );
}

export default ProfileForm;
