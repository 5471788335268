import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NAV_CONFIG } from "../../consts";
import "./styles.scss";

const NavBar = () => {
  const [isShowMale, setShowMale] = useState(false);
  const [isShowFemale, setShowFemale] = useState(false);

  const updateShow = (type, flag) => {
    if (type === "male") {
      setShowMale(flag);
    } else if (type === "female") {
      setShowFemale(flag);
    }
  };
  const isShow = (type) => {
    if (type === "male") {
      return isShowMale;
    } else if (type === "female") {
      return isShowFemale;
    }
    return false;
  };

  const tabs = NAV_CONFIG.map(({ url, title, tag, items }) => (
    <li key={"key" + tag} className="nav-item">
      {items ? (
        <div
          className="link"
          // to={url}
          onMouseOver={() => updateShow(tag, true)}
          onFocus={() => updateShow(tag, true)}
          onMouseLeave={() => updateShow(tag, false)}
          onBlur={() => updateShow(tag, false)}
        >
          <div key={"title-" + tag}>{title}</div>
          <ul
            className={`nav sub-menu sub-menu-${tag} ${
              isShow(tag) ? "show" : ""
            }`}
          >
            {items.map(({ url: subUrl, title: subTitle }, idx) => (
              <li
                key={`menu-item-${tag}-${idx}`}
                className={`menu-item menu-item-${tag}-${idx}`}
              >
                <Link
                  to={url + "&" + subUrl}
                  onClick={() => updateShow(tag, false)}
                >
                  <div>{subTitle}</div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <Link to={url}>
          <div>{title}</div>
        </Link>
      )}
    </li>
  ));

  return <ul className="nav nav-bar">{tabs}</ul>;
};

export default NavBar;
