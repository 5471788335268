/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import FormData from "form-data";
import config from "../consts/config";

axios.interceptors.request.use(function (conf) {
  let token = localStorage.getItem("sb_auth_token");
  conf.headers["Access-Control-Allow-Origin"] = "*";
  conf.headers["Content-Type"] =  "application/json";
  if (token) {
    conf.headers["Authorization"] = `Bearer ${token}`;
  }
  if (config.API_URL) {
    conf.baseURL = config.API_URL;
  }
  return conf;
});

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*' // for all requests
axios.defaults.headers.common['Content-Type'] = 'application/json' // for all requests

async function get(path, headers = {}) {
  return await axios.get(path, { headers });
}

async function del(path, headers = {}) {
  return await axios.delete(path, { headers });
}

async function post(path, body, headers = {}) {
  return await axios.post(path, body, { headers });
}

async function put(path, body, headers = {}) {
  return await axios.put(path, body, { headers });
}

async function up(path, file) {
  const data = new FormData();
  data.append("upfile", file);
  return await post(path, data);
}

async function up_many(path, files) {
  const data = new FormData();
  files.forEach((file) => {
    data.append("upfiles", file);
  });
  return await post(path, data);
}

export default { get, del, post, put, up, up_many };
