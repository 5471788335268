import React, { useEffect, useState } from "react";
import {
  ConfirmEmailForm,
  ResetPasswordForm,
  YouAreOnListForm,
  YouSubmitBidForm,
} from "../components/notice";

const NoticePage = () => {
  const [key, setKey] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    const splits = window.location.pathname.split("/");
    const path1 = splits[2];
    const itemId = splits[3];

    setKey(path1);
    setId(itemId);
  }, []);

  return (
    <React.Fragment>
      <div className="page notice-page">
        <div className="container">
          {key === "reset-password" && <ResetPasswordForm />}
          {key === "confirm-email" && <ConfirmEmailForm />}
          {key === "be-on-list" && <YouAreOnListForm id={id} />}
          {key === "submit-bid" && <YouSubmitBidForm id={id} />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NoticePage;
