import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { dummyViewMoreArray } from "../../../consts";
import ProductThumb from "../productThumb";

import "./styles.scss";

function ShopViewMore(props) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(dummyViewMoreArray);
  }, []);

  const renderTitle = () => {
    return (
      <div className="viewmore-header flex flex-jc my-10 lg-px-10 md-px-20 sm-px-0">
        <div className="viewmore-left md-text-lg lg-text-lg sm-text-base">
          View More Like This
        </div>
        <Link className="viewmore-right flex" to="/list">
          View All <i className="fas fa-long-arrow-alt-right" />
        </Link>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="viewmore-results">
        <div className="viewmore-results-list">
          {products.map((product, idx) => (
            <div
              key={"results" + idx}
              className="results-item w-full lg-w-1-2 md-w-1-4"
            >
              <ProductThumb {...product} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="viewmore-form lg-px-10 md-px-20 sm-px-0">
      {renderTitle()}
      {renderResults()}
    </div>
  );
}

export default ShopViewMore;
