import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import {
  renderGroup,
  convertCurrency,
  dummyViewMoreArray,
  renderInputField,
} from "../../consts";

import "./styles.scss";

function CheckoutForm(props) {
  const { initialValues } = props;
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const cart0 = { ...dummyViewMoreArray[0], quantity: 1 };
    const cart1 = { ...dummyViewMoreArray[1], quantity: 1 };

    setCartItems([cart0, cart1]);
  }, []);

  const onHandleSubmit = (formData) => {
    console.log("form", formData);
    
  };

  const handleNext = () => {
    //
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title px-20">Checkout</p>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="bg-white w-full sm-w-3-5 md-w-2-3">
        <Form onSubmit={onHandleSubmit} initialValues={initialValues}>
          {({ handleSubmit, submitting, touched, errors }) => (
            <form onSubmit={handleSubmit} className="info-form">
              <div className="w-full shop-detail">Shipping Details</div>
              <div className="w-full line" />

              <div className="w-full sub-title">Contact Information</div>

              {renderGroup(
                renderInputField("first_name", "First Name"),
                renderInputField("last_name", "Last Name")
              )}
              {renderGroup(
                renderInputField("email", "Your Email"),
                renderInputField("phone", "Phone Number")
              )}

              <div className="w-full sub-title">Spipping address</div>

              {renderGroup(
                renderInputField("country", "Country"),
                renderInputField("state", "State")
              )}

              {renderGroup(renderInputField("street", "Street address"))}

              {renderGroup(
                renderInputField("city", "City"),
                renderInputField("zipCode", "Zip Code")
              )}

              <div className="w-1-2">
                <button className="btn-dark" type="button" onClick={handleNext}>
                  <span>Next</span>
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  };
  const renderRow = (product, index) => {
    const { link, detail, price, quantity } = product;

    return (
      <div
        key={"order-row" + index}
        className="order-item flex flex-wrap"
      >
        <div className="image">
          <div className="left">
            <img src={"." + link} alt="" />
          </div>
        </div>

        <div className="product">
          <div className="detail">{detail}</div>
          <div className="other flex">
            <div className="price">{convertCurrency(price)}</div>
            <div className="quantity">Qty {quantity}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = () => {
    let subTotal = 0;
    cartItems.forEach((item) => {
      subTotal += item.price * item.quantity;
    });

    return (
      <div className="order-total w-full sm-w-2-5 md-w-1-3">
        <div className="order-header">
          Order Summary <span>({cartItems.length} items)</span>
        </div>

        <div className="order-body">
          <div className="order-content">
            {cartItems.length > 0 ? (
              cartItems.map((item, idx) => renderRow(item, idx))
            ) : (
              <div className="results-empty">No Items</div>
            )}
          </div>
          <div className="total w-full flex flex-jc">
            <div>Est. Total Before Tax</div>
            <div>{convertCurrency(subTotal)}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="checkout-form lg-px-10 md-px-20 sm-px-0">
      {renderTitle()}
      <div className="flex flex-wrap">
        {renderForm()}
        {renderOrder()}
      </div>
    </div>
  );
}

export default CheckoutForm;
