import React from "react";
import "./styles.scss";

const ResetPasswordContent = () => {
  const resetUrl = "https://sealedbids.com/reset";

  return (
    <div className="reset-password content px-10 sm-px-30 my-10">
      <div className="sub-title">Password Reset</div>

      <div>
        If you lost your password or wish to reset it, use this lisk below to
        get started.
      </div>

      <button className="btn-confirm" type="btn">
        <a href={resetUrl}>Reset Your Password</a>
      </button>

      <div>
        If you didn't request a password reset, you can safely ignore this
        email. Only a person with access to your email can reset your account
        password.
      </div>

      <div className="py-30">
        Sincerely,
        <br />
        SealedBids team
      </div>
    </div>
  );
};

export default ResetPasswordContent;
