import React from 'react';
import { ToastContainer } from 'react-toastify';
import AppRoutes from '../routes/routes';

import "./App.css";

function App() {
  return (
    <React.Fragment>
      <AppRoutes />
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
