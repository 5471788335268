import React from "react";
import { convertCurrency, Placeholder } from "../../../consts";
import config from "../../../consts/config";

import "./styles.scss";

const ProductThumb = ({ link, image_keys, price, description }) => {
  const getImage = () => {
    if (link) {
      return link;
    }
    
    if (!image_keys || image_keys.length === 0) {
      return Placeholder;
    }

    return config.IMAGE_URL + encodeURI(image_keys[0]);
  }
  return (
    <div className="product-thumb">
      <div className="product-thumb-icon">
        <img src={getImage()} onError={({target}) => {
          target.onError = null;
          target.src = Placeholder;
        }} alt="" />
      </div>
      <div className="product-thumb-price">{convertCurrency(price)}</div>
      <div className="product-thumb-detail">{description}</div>
    </div>
  );
};

export default ProductThumb;
