import React from 'react';
import CheckoutForm from '../components/checkout';

const Checkout = () => {
  
  return (
    <React.Fragment>
      
      <div className="page checkout-page mt-50">
        <div className="checkout-page-content">
          <div className="container">
            <CheckoutForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Checkout;
