import React, { useEffect, useState } from "react";

import Loader from "../loader";
import "./styles.scss";
import { renderGroup, renderInputField } from "../../consts";
import { Form } from "react-final-form";
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { post_payment_intents } from "../../utils/listings";
import { showError } from "../../utils";

function CardForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const { handleSubmit, handlePrev, prevText, nextText } = props;
  const [intent, setIntent] = useState(props.intent);
  const [isSubmitting, setSubmitting] = useState(false);

  const onHandleSubmit = async (formData) => {
    if (isSubmitting) {
      return;
    }
    console.log(formData);
    if (!stripe || !elements) {
      return;
    }
    setSubmitting(true);
    let newIntent = intent;
    try {
      if (!newIntent) {
        newIntent = await post_payment_intents();
        setIntent(newIntent);
      }
    } catch (e) {
      console.log(e);
      setSubmitting(false);
      showError();
      return;
    }
    const { cardholder } = formData;
    try {
      const cardElement = elements.getElement(CardNumberElement);

      if (stripe) {
        const { error, setupIntent } = await stripe.confirmCardSetup(
          newIntent.client_secret,
          {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: cardholder,
              },
            },
          }
        );

        console.log("[confirm]", setupIntent, "Error:", error);
        setSubmitting(false);
        if (error) {
          showError();
          return;
        }
        if (handleSubmit) {
          handleSubmit(formData);
        }
      } else {
        setSubmitting(false);
        console.log("Stripe.js hasn't loaded yet.");
      }
    } catch (e) {
      console.log(e);
      setSubmitting(false);
      showError();
    }
  };

  const handleBlur = () => {
    // console.log("[blur]");
  };
  const handleChange = (change) => {
    // console.log("[change]", change);
  };
  const handleClick = () => {
    // console.log("[click]");
  };
  const handleFocus = () => {
    // console.log("[focus]");
  };
  const handleReady = () => {
    // console.log("[ready]");
  };

  const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
          ...(padding ? { padding } : {}),
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };

  const renderBody = () => {
    const fontSize = 14;
    return (
      <>
        <div className="w-full title">Cardholder Name</div>
        {renderInputField("cardholder", "")}

        <div className="w-full title">Card Number</div>
        <div className="w-full">
          <CardNumberElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(fontSize)}
          />
        </div>
        <div className="w-full title">Expiration date</div>
        <div className="w-full">
          <CardExpiryElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(fontSize)}
          />
        </div>
        <div className="w-full title">CVC</div>
        <div className="w-full">
          <CardCvcElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(fontSize)}
          />
        </div>
      </>
    );
  };

  const renderForm = () => {
    return (
      <Form onSubmit={onHandleSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="register-form card-form">
            {renderBody()}
            <div className="register-buttons">
              {renderGroup(
                <div className="w-full">
                  <button
                    className="btn-prev"
                    type="button"
                    disabled={submitting}
                    onClick={handlePrev}
                  >
                    <span>{prevText || "Previous"}</span>
                  </button>
                </div>,

                <div className="w-full">
                  <button
                    className="btn-next"
                    type="submit"
                    disabled={submitting}
                  >
                    <span>{nextText || "Next"}</span>
                  </button>
                </div>
              )}
            </div>
          </form>
        )}
      </Form>
    );
  };

  return (
    <>
      {renderForm()}
      {isSubmitting && <Loader />}
    </>
  );
}

export default CardForm;
