import React from "react";
import { Navigate } from "react-router";
import useAppState from "../appState";

function PrivateRoute(props) {
  const { component: Component, ...rest } = props;
  const {
    account: { isAuthenticated },
  } = useAppState();

  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Navigate
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    />
  );
}

export default PrivateRoute;
