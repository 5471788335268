import NoticeForm from "./notice";
import ConfirmEmailContent from "./confirmEmail";
import ResetPasswordContent from "./resetPassword";
import YourBidContent from "./yourBid";

export const ConfirmEmailForm = () => {
  return <NoticeForm content={ConfirmEmailContent()} />;
};

export const ResetPasswordForm = () => {
  return <NoticeForm content={ResetPasswordContent()} />;
};

export const YouAreOnListForm = ({id}) => {
  return <NoticeForm content={YourBidContent(id, 0)} />;
};

export const YouSubmitBidForm = ({id}) => {
  return <NoticeForm content={YourBidContent(id, 1)} />;
};
