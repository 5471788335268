import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App";
import { StateProvider } from "./appState";

import 'bootstrap/dist/css/bootstrap.css';
import "./assets/css/global.css";
import "./assets/css/fontawesome.css";
import "./assets/css/lib-style.css";
import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement("#root");

const authToken = localStorage.getItem("sb_auth_token");

ReactDOM.render(
  <Router>
    <StateProvider authToken={authToken}>
      <App />
    </StateProvider>
  </Router>,
  document.getElementById("root")
);
