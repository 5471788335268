import React, { Suspense, useEffect } from "react";
import useAppState, { useAppDispatch } from "../appState";
import { login } from "../actions/account";
import PrivateRoute from "./privateRoute";
import { GuestLayout, UserLayout } from "./layouts";
import { useLocation, useNavigate } from "react-router-dom";

function AppRoute(props) {
  const {
    account: { isAuthenticated },
  } = useAppState();
  const dispatch = useAppDispatch();

  let location = useLocation();
  let navigate = useNavigate();

  const { component: Component, isPublic, keepGuestLayout, ...rest } = props;

  const Layout = /*isAuthenticated && */ !keepGuestLayout
    ? UserLayout
    : GuestLayout;

  useEffect(() => {
    if (window.location.hash.length > 1) {
      const hashParams = new URLSearchParams(window.location.hash.slice(1));
      const idToken = hashParams.get("id_token");
      const authToken = hashParams.get("access_token");
      console.log("%caccess token %o", "color: chartreuse", authToken);
      console.log("%cid token %o", "color: gold", idToken);
      if (authToken && idToken) {
        localStorage.setItem("sb_auth_token", authToken);
        localStorage.setItem("id_token", idToken);
        dispatch(login({authToken, idToken}, true));
        navigate("/");
      }
    }
  }, []);

  if (isAuthenticated) {
    if (location.pathname === "/login" || location.pathname === "/signup") {
      // window.location = "/";
      // return;
    }
  }
  return (
    <Layout location={location}>
      <Suspense fallback={<div className="text-center">Loading...</div>}>
        {isPublic ? (
          <Component {...rest} />
        ) : (
          <PrivateRoute component={Component} {...rest} location={location} />
        )}
      </Suspense>
    </Layout>
  );
}

export default AppRoute;
