import React, { useEffect, useState } from "react";
import { convertCurrency, getImageUri, Placeholder } from "../../consts";
import { showError } from "../../utils";
import { get_bid, get_listing } from "../../utils/listings";
import "./styles.scss";

const YourBidContent = (id, type) => {
  console.log("YourBidContent", id, type);
  const [firstName, setFirstName] = useState("");
  const [itemNum, setItemNum] = useState("");
  const [itemImage, setItemImage] = useState("");
  const [price, setPrice] = useState(1499.99);
  const [itemName, setItemName] = useState("");
  const [detail, setDetail] = useState("");
  const [expireTime, setExpireTime] = useState(new Date());
  const [remainTime, setRemainTime] = useState(0);

  const itemUrl =
    "https://sealedbids.com/xxxxxx/xxxxx/xxxxxx/xxxxxxxxxxx/x//x////xxx";

  const subTitle = type === 1 ? "You submitted a bid" : "You are on the list!";
  const subDesc =
    type === 1
      ? `Your bid for item #${itemNum} was submitted.`
      : `Your item #${itemNum} was succesfully created.`;

  useEffect(() => {
    const str = localStorage.getItem("profile");
    if (str) {
      const profile = JSON.parse(str);
      setFirstName(profile.firstName);
    }

    if (id) {
      if (type === 1) {
        getBidInfo(id);
      }
      else {
        getItemInfo(id);
      }
    }
  }, [id]);

  useEffect(() => {
    let diff =
      ((expireTime.getTime() / 1000) >> 0) -
      ((new Date().getTime() / 1000) >> 0);
    if (diff < 0) {
      diff = 0;
    }
    setRemainTime(diff);
  }, [expireTime]);

  const getBidInfo = async (id) => {
    try {
      const bid = await get_bid(id);
      setItemNum(bid.item_id);
      setPrice(bid.amount);
      setItemName(bid.name);
      setDetail(bid.description);

      if (bid.image_keys && bid.image_keys[0]) {
        setItemImage(bid.image_keys[0]);
      }

      setExpireTime(new Date(bid.exp_time));
    } catch (e) {
      showError();
    }
  };

  const getItemInfo = async (id) => {
    try {
      const item = await get_listing(id);
      setItemNum(item.item_id);
      setPrice(item.price);
      setItemName(item.name);
      setDetail(item.description);

      if (item.image_keys && item.image_keys[0]) {
        setItemImage(item.image_keys[0]);
      }

      setExpireTime(new Date(item.exp_time));
    } catch (e) {
      showError();
    }
  };

  const getHMS = () => {
    let h = 0,
      m = 0,
      s = 0;
    if (remainTime !== 0) {
      let diff = remainTime;

      h = (diff / 3600) >> 0;
      diff = diff % 3600;

      m = (diff / 60) >> 0;
      s = diff % 60;
    }
    return { h, m, s };
  };

  const { h, m, s } = getHMS();

  return (
    <div className="be-on-list content px-10 sm-px-30 my-10">
      <div className="sub-title">{subTitle}</div>

      <div>
        Hi {firstName},
        <br />
        <br />
        {subDesc}
      </div>

      <button className="btn-confirm" type="btn">
        <a href={itemUrl}>View Item</a>
      </button>

      <div className="product">
        <div className="product-image">
          <img
            src={getImageUri(itemImage)}
            alt=""
            onError={({ target }) => {
              target.onError = null;
              target.src = Placeholder;
            }}
          />
        </div>
        <div className="product-detail">
          <div className="product-detail-title">Starting Price</div>
          <div className="product-detail-price">{convertCurrency(price)}</div>
          <div className="product-detail-label">{itemName}</div>

          <div className="product-detail-time">
            <div className="product-detail-content">
              <div className="product-detail-number">{h}</div>hrs
            </div>
            <div className="product-detail-content">
              <div className="product-detail-number">{m}</div>mins
            </div>
            <div className="product-detail-content">
              <div className="product-detail-number">{s}</div>secs
            </div>
          </div>
        </div>
      </div>

      <div className="detail">Description</div>
      <div className="mb-40">{detail}</div>
    </div>
  );
};

export default YourBidContent;
