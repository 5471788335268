import jwtDecode from "jwt-decode";
import { SET_AUTHENTICATED, LOGIN, LOGOUT } from "../../actions/account";

const initialState = { isAuthenticated: false };

const isExpired = (token) => {
  const decoded = jwtDecode(token);
  const now = (Date.now() / 1000) >> 0;
  console.log('exp', decoded.exp);
  return !decoded || decoded.exp >= now;
};

function account(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: isExpired(action.payload.authToken),
      };
    case LOGIN:
      return {
        ...state,
        isAuthenticated: isExpired(action.payload.loginResult.authToken),
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}

export default account;
