import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../consts";

import "./styles.scss";

const NoticeForm = ({ content }) => {
  const renderHeader = () => {
    return (
      <div className="logo-header mb-10">
        <Link to="/">
          <Logo />
        </Link>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="support-footer">
        <div className="question w-full sm-w-2-3 sm-pl-30">
          <span>Have a questions?</span>
          <Link to={"/about"}>Reach out to our team</Link>
        </div>
        <div className="logo w-full sm-w-1-3">
          <Logo />
        </div>
      </div>
    );
  };

  return (
    <div className="notice-form">
      <div className="form-container">
        {renderHeader()}

        {content}

        {renderFooter()}
      </div>
    </div>
  );
};

export default NoticeForm;
