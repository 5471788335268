import { Field } from "react-final-form";
import { Input } from "../components/ui";
import Select from 'react-select';

export const renderGroup = (child1, child2) => {
  return (
    <div className="form-group">
      <div className="flex items-center">
        {child1}
        {child2}
      </div>
    </div>
  );
};

export const renderInput = (name, placeholder, disabled) => {
  return (
    <Field
      className={"form-control input-" + name}
      type="text"
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      component={Input}
    />
  );
};

export const renderNumber = (name, placeholder) => {
  return (
    <Field
      className={"form-control input-" + name}
      type="number"
      name={name}
      placeholder={placeholder}
      component={Input}
    />
  );
};

export const renderInputField = (name, placeholder, disabled) => {
  return (
    <div className="w-full">
      <Field
        className={"form-control input-" + name}
        type="text"
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        component={Input}
      />
    </div>
  );
};

export const renderPasswordField = (name, placeholder) => {
  return (
    <div className="w-full">
      <Field
        className={"form-control input-" + name}
        type="password"
        name={name}
        placeholder={placeholder}
        component={Input}
      />
    </div>
  );
};

export const renderSelect = (key, options, value, onChange, placeholder) => {
  return (
    <Select
      className="item-select"
      name={key}
      options={options}
      value={value}
      onChange={onChange}
      classNamePrefix="select"
      isSearchable={false}
      placeholder={placeholder || "Choose from the list"}
    />
  );
};
