import React from 'react';
import ProductListForm from '../components/shop/productList';

const ProductList = () => {
  
  return (
    <React.Fragment>
      
      <div className="page product-list-page">
        <div className="product-list-page-content">
          <div className="container">
            <ProductListForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductList;
