import React, { useEffect, useState } from "react";
import {
  renderGroup,
  convertCurrency,
  dummyViewMoreArray,
  getImageUri,
  Placeholder,
} from "../../consts";

import "./styles.scss";
import ShopViewMore from "./../shop/shopViewMore";
import { Link } from "react-router-dom";
import { get_payment_wins } from "../../utils/listings";
import { checkAuth } from "../../utils";
import Loader from "../loader";

function CartForm(props) {
  const [isLoading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const cartHeader = ["item", "price", "qty", "subtotal", ""];

  useEffect(() => {
    setLoading(true);
    getWins();
  }, []);

  async function getWins() {
    try {
      let wins = await get_payment_wins(true);
      setCartItems([...wins]);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      checkAuth(e);
    }
  }

  const handleClear = () => {
    //
  };

  const handleContinue = () => {
    //
  };

  const handleRemove = (index) => {
    //
  };

  const handleEdit = (index) => {
    //
  };

  const renderTitle = () => {
    return (
      <div className="mb-30">
        <p className="form-title px-20">Shopping Cart</p>
      </div>
    );
  };
  const renderRow = (product, index) => {
    const { name, amount, image_keys } = product;
    const quantity = 1;

    return (
      <div
        key={"cart-row" + index}
        className="row-item px-10 py-10 md-px-10 sm-py-20 md-py-20 flex flex-wrap"
      >
        <div className="product flex w-full sm-w-2-5 md-w-2-5 pb-20 sm-pb-0 md-pb-0">
          <div className="left">
            <img
              src={getImageUri(image_keys[0])}
              alt=""
              onError={({ target }) => {
                target.onError = null;
                target.src = Placeholder;
              }}
            />
          </div>
          <div className="right">
            <div className="detail">{name}</div>
          </div>
        </div>

        <div className="other flex w-full sm-w-3-5 md-w-3-5">
          <div className="price w-2-5">
            <div className="value">{convertCurrency(amount)}</div>
          </div>

          <div className="quantity w-1-5">
            <div className="value">{quantity}</div>
          </div>

          <div className="price w-2-5">
            <div className="value">{convertCurrency(amount * quantity)}</div>
          </div>

          <div className="more flex flex-wrap">
            <span>
              <i className="fa fa-times" onClick={() => handleRemove(index)} />
            </span>
            <span>
              <i className="fas fa-pen" onClick={() => handleEdit(index)} />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="results w-full sm-w-3-5 md-w-2-3">
        <div className="results-header w-full my-10 flex ">
          <div className="results-header-item flex w-full sm-w-2-5 md-w-2-5 pb-20 sm-pb-0 md-pb-0">
            Item
          </div>

          <div className="other flex w-full sm-w-3-5 md-w-3-5">
            <div className="results-header-item w-2-5">Price</div>

            <div className="results-header-item w-1-5">Qty</div>

            <div className="results-header-item w-2-5">Subtotal</div>

            <div className="results-header-item more" />
          </div>
        </div>

        <div className="line w-full" />

        <div className="results-list my-10">
          {cartItems.length > 0 ? (
            cartItems.map((item, idx) => renderRow(item, idx))
          ) : (
            <div className="results-empty">No Items</div>
          )}
        </div>

        {cartItems.length > 0 && (
          <div className="results-buttons w-full sm-w-4-5 md-w-3-5">
            {renderGroup(
              <div className="w-full">
                <button
                  className="btn-gray btn-clear"
                  type="button"
                  onClick={handleClear}
                >
                  <span>Clear Shopping Cart</span>
                </button>
              </div>,
              <div className="w-full">
                <button
                  className="btn-normal btn-continue"
                  type="button"
                  onClick={handleContinue}
                >
                  <span>Continue Shopping</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderOrder = () => {
    let subTotal = 0;
    cartItems.forEach((item) => {
      subTotal += item.amount * 1;
    });

    return (
      <div className="order-total w-full sm-w-2-5 md-w-1-3">
        <div className="order-header">
          Order Total <span>({cartItems.length} items)</span>
        </div>

        <div className="order-body">
          <div className="order-content">
            <div className="flex flex-jc">
              <div>Subtotal</div>
              <div>{convertCurrency(subTotal)}</div>
            </div>

            <div className="flex flex-jc">
              <div>Shipping</div>
              <div>FREE</div>
            </div>

            <div className="total flex flex-jc">
              <div>Est. Total Before Tax</div>
              <div>{convertCurrency(subTotal)}</div>
            </div>
          </div>

          <div className="w-full">
            <button className="btn-dark" type="button">
              <span>
                <Link to="/checkout">CheckOut</Link>
              </span>
            </button>
          </div>
          <div className="w-full">
            <button className="btn-dark" type="button">
              <span>PayPal</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="cart-form lg-px-10 md-px-20 sm-px-0">
      {renderTitle()}
      <div className="flex flex-wrap">
        {renderResults()}
        {renderOrder()}
      </div>

      <ShopViewMore />
      {isLoading && <Loader />}
    </div>
  );
}

export default CartForm;
