import React from 'react';
import CartForm from '../components/cart';

const Cart = () => {
  
  return (
    <React.Fragment>
      
      <div className="page cart-page mt-50">
        <div className="cart-page-content">
          <div className="container">
            <CartForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Cart;
