import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const PortalMenu = ({ menu, selected, onSelectItem }) => {
  const menus = Object.entries(menu).map(([key, list]) => (
    <div key={"menu_" + key}>
      <span className="portal-type">{key}</span>

      {list.map(({ url, title }, idx) => (
        <li
          key={"key_" + key + idx}
          className={`nav-item${selected === url ? " selected" : ""}`}
        >
          <div className="bar" />
          <Link
            key={"link_" + key + idx}
            to="#"
            onClick={(e) => onSelectItem(e, url)}
          >
            <div>{title}</div>
          </Link>
        </li>
      ))}
      <div key={"line_" + key} className="line" />
    </div>
  ));

  return <ul className="portal-menu nav nav-bar">{menus}</ul>;
};

export default PortalMenu;
