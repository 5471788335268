import React from "react";
import { Link } from "react-router-dom";

import withRouter from "../../routes/withRouter";
import NavBar from "./../navBar";

import "./styles.scss";
import useAppState from "../../appState";
import { Cart, Logo, Profile } from "../../consts";

function UserHeader(props) {
  const { router } = props;

  const {
    account: { isAuthenticated },
  } = useAppState();

  const renderTitle = () => {
    return (
      <div className="flex flex-jc mx-0 row-mx-15">
        <div className="header-logo">
          <Link className="logo lg-pl-30 sm-pl-20 md-pl-60 pl-10" to="/">
            <Logo />
          </Link>
        </div>
        <div className="header-right">
          {!isAuthenticated ? (
            <Link className="header-login" to="/login">
              Login
            </Link>
          ) : (
            <>
              <Link className="header-profile" to="/profile">
                <Profile />
              </Link>
              <Link className="header-cart" to="/cart">
                <Cart />
              </Link>
              <div className="header-cart-count">1</div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`header${
        router.location.pathname === "/" ? " main-page-header" : ""
      }`}
    >
      <div className="header-title">{renderTitle()}</div>
      <NavBar />
    </div>
  );
}

export default withRouter(UserHeader);
